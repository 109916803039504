import { ActionsObservable, combineEpics } from "redux-observable";
import { defer } from "rxjs";
import { concatMap, switchMap } from "rxjs/operators";
import { ofType } from "../reduxObservableUtils";
import { Dependancies } from "../storeTypes";
import * as io from "io-ts";
import { pipe } from "fp-ts/lib/function";
import { match } from "fp-ts/lib/TaskEither";
import { dispatchNetworkError } from "./errorHandler";
import { Transaction, transactionsDecoder } from "../decoders";

export const key = "transaction";

const getTransactionsLoadingType = "user/GET_TRANSACTION/LOADING";
const getTransactionsSuccessType = "user/GET_TRANSACTION/LOADED";
const getTransactionsFailedType = "user/GET_TRANSACTION/FAILED";

export const getTransactionsSuccessAction = (transactions: any) =>
  ({ type: getTransactionsSuccessType, transactions: transactions } as const);

export const getTransactionsFailedAction = {
  type: getTransactionsFailedType,
} as const;

export const getTransactionsAction = () =>
  ({
    type: getTransactionsLoadingType
  } as const);

type Action =
  | typeof getTransactionsFailedAction
  | ReturnType<
      | typeof getTransactionsAction
      | typeof getTransactionsSuccessAction
    >;

export type State =
  | { tag: "initial" }
  | { tag: "loading" }
  | { tag: "loaded", data: [] }
  | { tag: "error" };

const initialState: State = { tag: "initial" };

export const reducer = (state: State = initialState, action: any): State => {
  switch (action.type) {
    case getTransactionsLoadingType:
      return {
        tag: "loading",
      };
    case getTransactionsSuccessType:
      return {
        tag: "loaded",
        data: action.transactions
      };
    case getTransactionsFailedType:
      return {
        tag: "error",
      };
    default:
      return state;
  }
};

const transactionEpic = (
  action$: ActionsObservable<Action>,
  _: any,
  deps: Dependancies
) =>
  action$.pipe(
    ofType(getTransactionsLoadingType),
    concatMap((x) =>
      pipe(
        deps.request.get(
          `userTransactions`,
          transactionsDecoder
        ),
        match(dispatchNetworkError, (transaction: any) => {
          return getTransactionsSuccessAction(transaction);
        }),
        defer
      )
    )
  );

export const epic = combineEpics(transactionEpic);
const baseSelector: (a: any) => State = (s) => s[key];
const transactionsSelector: (a: any) => Transaction[] = (s) => s[key].data;
export const Selectors = {
  all: baseSelector,
  transactions: transactionsSelector,
};


export const initialState1 = [
    {
        "Market": "France Baseload",
        "Product": "AUG23",
        "Last_Today": 82.10,
    },
    {
        "Market": "Germany Peak",
        "Product": "2024",
        "Last_Today": 15.4,
    },
    {
        "Market": "Belgium Baseload",
        "Product": "AUG23",
        "Last_Today": 5.46,
    },
    {
        "Market": "TTF Hi Cal 51.6",
        "Product": "Q124",
        "Last_Today": 41.4,
    },
    {
        "Market": "Italy Baseloads",
        "Product": "AUG23",
        "Last_Today": 112.9,
    },
    {
        "Market": "Italy Baseload",
        "Product": "Q124",
        "Last_Today": 90.7,
    },
    {
        "Market": "Germany Baseload",
        "Product": "AUG23",
        "Last_Today": 82.10,
    },
    {
        "Market": "Germany Peaks",
        "Product": "2024",
        "Last_Today": 15.4,
    },
    {
        "Market": "Belgium Peaks",
        "Product": "AUG23",
        "Last_Today": 5.46,
    },
    {
        "Market": "TTF Hi Cal 51.5",
        "Product": "Q124",
        "Last_Today": 41.4,
    },
    {
        "Market": "Baseload",
        "Product": "AUG23",
        "Last_Today": 112.9,
    }
];