import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import * as R from "ramda";

type CustomPromptType = {
  history?: any;
};

const CustomPrompt = ({ history }: CustomPromptType) => {
  const [state, setState] = useState<any>({
    show: false,
    message: null,
    cbParam: null,
  });

  const { show, message, cbParam } = state;

  useEffect(() => {
    history.getUserConfirmation = (message: string, cb: any) =>
      setState({
        show: true,
        message: message,
        cbParam: cb,
      });
  }, [history]);

  const callback = (val: boolean) => {
    return setState({
      cbParam: cbParam(val),
      message: null,
      show: false,
    });
  };

  return (
    <>
      {R.when(R.equals(true), () => (
        <div
          className="modal mapping-modal z-index-modal fade show d-block"
          id="configModal"
          role="dialog"
          aria-hidden="true"
        >
          <div className="overlay fadeIn" />
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-close-btn">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => callback(false)}
                >
                  <i className="fas fa-times-circle"></i>
                </button>
              </div>
              <div className="modal-header text-center">
                <h5 className="modal-title">UnSaved Changes</h5>
              </div>
              <div className="modal-body text-center">{message}</div>
              <div className="modal-footer">
                <button
                  className="btn btn-danger rounded-pill mx-2"
                  onClick={() => callback(true)}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      ))(show)}
    </>
  );
};

export default withRouter(CustomPrompt);
