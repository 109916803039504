export const key = "menu";

export const userMenuClickedAction = {
  type: "management/menu/USER_MENU_CLICKED",
} as const;

export enum actionTypes {
  TOGGLE_MENU = "axpo/menu/TOGGLE_MENU",
  CLOSE_MENU = "axpo/menu/CLOSE_MENU",
  OPEN_MENU = "axpo/menu/OPEN_MENU",
  TOGGLE_PIN_MENU = "axpo/menu/TOGGLE_PIN_MENU",
  PIN_OPEN_MENU = "axpo/menu/PIN_OPEN_MENU",
  UNPIN_OPEN_MENU = "axpo/menu/UNPIN_OPEN_MENU",
}

type Actions = typeof userMenuClickedAction;

const initialMenuState = {
  isUserMenuOpen: false,
};

type State = typeof initialMenuState;

export function reducer(
  state: State = initialMenuState,
  action: Actions
): State {
  switch (action.type) {
    case "management/menu/USER_MENU_CLICKED":
      return { isUserMenuOpen: !state.isUserMenuOpen };
    default:
      return state;
  }
}

export const Selectors = {
  all: (state: any): State => state[key],
  isUserMenuOpen: (state: any): boolean => state[key].isUserMenuOpen,
};
