import { useEffect, useState } from "react";
import { CloseTransaction } from "../../redux/decoders";
import styles from "./closeTransactionModal.module.scss";
import {
  Selectors as closeTransactionSelector,
  postCloseTransactionInitialAction,
  postCloseTransactionSetInitialAction
} from "../../redux/modules/closeTransaction";
import { useDispatch, useSelector } from "react-redux";

const CloseTransactionModal = (props: {
  showModal: boolean;
  transaction: CloseTransaction;
  sendTransaction: () => void;
  close: () => void;
}) => {
  const dispatch = useDispatch();
  const [lotAmount, setLotAmount] = useState(1);
  const userDataTable = useSelector(closeTransactionSelector.all);
  const [isChecked, setIsChecked] = useState(false);
  const handleSelectChange = (e: any) => {
    setLotAmount(e.target.value);
  };
  useEffect(() => {
    if (userDataTable.tag === "loaded") {
      props.close();
    }
  }, [userDataTable]);
  return props.showModal ? (
    <div className={`${styles.modalPage}`}>
      <div className={styles.overlay} />
      <div className={styles.modalContainer}>
        <div className={styles.modalContent}>
          <div className={styles.modalContentContainer}>
            {userDataTable.tag === "error" ? (
              <>
                <div className={styles.header}>Transaction Failed</div>
                <div className={styles.body}>
                  {props.transaction.type} {props.transaction.productName}{" "}
                  contract at{" "}
                  {props.transaction.lotSize || 0 * props.transaction.lotAmount}{" "}
                  €/Mwh for product {props.transaction.marketName} failed. The
                  transaction has not been closed.
                </div>
                <div className={styles.footer}>
                  <button
                    className={styles.closeButton}
                    onClick={() => {
                      dispatch(postCloseTransactionSetInitialAction());
                      setLotAmount(1);
                      setIsChecked(false);
                      props.close();
                    }}
                  >
                    close
                  </button>
                </div>
              </>
            ) : userDataTable.tag === "loaded" ? (
              <>
                <div className={styles.header}>Transaction Closed Successfully</div>
                <div className={styles.body}>
                  <table className={styles.noBorder}>
                    <thead>
                      <tr>
                        <td>Lot Size:</td>
                        <td>
                          {props.transaction.lotSize}
                        </td>
                        <td>MW</td>
                      </tr>
                      <tr>
                        <td>Number of Lots:</td>
                        <td>
                          {lotAmount}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Total Quantity:</td>
                        <td className="rightAlignPadRigth">
                          <span>
                            {props.transaction.lotSize *
                              props.transaction.lotAmount}
                          </span>
                        </td>
                        <td>MW</td>
                      </tr>
                      <tr>
                        <td>Total Amount: </td>
                        <td className="rightAlignPadRigth">
                          <span>
                            {Math.round(props.transaction.lotSize * lotAmount *
                              props.transaction.price* 100) / 100}
                          </span>
                        </td>
                        <td>€</td>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className={styles.footer}>
                  <button
                    className={styles.closeButton}
                    onClick={() => {
                      dispatch(postCloseTransactionSetInitialAction());
                      setLotAmount(1);
                      setIsChecked(false);
                      props.close();
                    }}
                  >
                    close
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className={styles.header}>Close Transaction</div>
                <div className={styles.body}>
                  <table className={styles.noBorder}>
                    <thead>
                      <tr>
                        <td>Lot Size:</td>
                        <td className="rightAlignPadRigth">
                          {props.transaction.lotSize}
                        </td>
                        <td>MW</td>
                      </tr>
                      <tr>
                        <td>Number of Lots:</td>
                        <td className="rightAlignPadRigth">
                          <span>
                            <select
                              value={lotAmount}
                              onChange={handleSelectChange}
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Total Quantity:</td>
                        <td className="rightAlignPadRigth">
                          <span>
                            {props.transaction.lotSize *
                              props.transaction.lotAmount}
                          </span>
                        </td>
                        <td>MW</td>
                      </tr>
                      <tr>
                        <td>Total Amount: </td>
                        <td className="rightAlignPadRigth">
                          <span>
                            {Math.round(props.transaction.lotSize * lotAmount *
                              props.transaction.price* 100) / 100}
                          </span>
                        </td>
                        <td>€</td>
                      </tr>
                    </thead>
                  </table>
                  <div>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={() => setIsChecked(!isChecked)}
                    />
                    I agree to close the transaction
                  </div>
                </div>
                <div className={styles.footer}>
                  <button
                    disabled={!isChecked}
                    className={styles.saveButton}
                    onClick={props.sendTransaction}
                  >
                    {userDataTable.tag === "loading" ? (
                      <div className="fa fa-spinner fa-spin"></div>
                    ) : (
                      <></>
                    )}
                    Send transaction
                  </button>
                  <button
                    className={styles.closeButton}
                    onClick={() => {
                      dispatch(postCloseTransactionSetInitialAction());
                      setLotAmount(1);
                      setIsChecked(false);
                      props.close();
                    }}
                  >
                    close
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CloseTransactionModal;
