import * as R from "ramda";

export const key = "errorHandler";

export enum actionTypes {
  SET_ERROR = "management/errorHandler/SET_ERROR",
  CLEAR_ERROR = "management/errorHandler/CLEAR_ERROR",
}

export type DetailsType = {
  title?: string | JSX.Element | null;
  message?: any;
  btnTitle?: any;
};
type Action =
  | {
      type: actionTypes.SET_ERROR;
      error: boolean;
      details: DetailsType | null;
    }
  | { type: actionTypes.CLEAR_ERROR };

export type errorModalType = {
  error?: boolean;
  details: DetailsType | null;
};

const errorDefault = {
  error: false,
  details: null,
};
export function reducer(
  state: errorModalType = errorDefault,
  action: Action
): any {
  switch (action.type) {
    case actionTypes.SET_ERROR:
      return {
        error: action.error,
        details: action.details,
      };
    case actionTypes.CLEAR_ERROR:
      return errorDefault;
    default:
      return state;
  }
}

export const setErrorAction = ({
  error = true,
  details = null,
}: errorModalType): { type: string} & any => {
  return {
    type: actionTypes.SET_ERROR,
    error,
    details,
  };
};

export const clearErrorAction = (): Action => ({
  type: actionTypes.CLEAR_ERROR,
});

export const Selectors: { all: (a: any) => errorModalType } = {
  all: R.prop(key),
};

export const dispatchNetworkError = (err: any): { type: string } => {
  if (err.status > 0) {
    if (err.status >= 400 || err.status <= 500) {
      if (R.hasPath(["response", "errorMessage"], err)) {
        return setErrorAction({
          details: {
            title: "Error: ".concat(err.status),
            message: err.response.errorMessage,
          },
        });
      }
      if (R.hasPath(["response", "title"], err)) {
        return setErrorAction({
          details: {
            title: "Error: ".concat(err.status),
            message: err.response.title,
          },
        });
      }
      return setErrorAction({
        details: {
          title: "Error: ".concat(err.status),
          message: "An error has occurred. Please contact support.",
        },
      });
    }
    return setErrorAction({
      details: {
        title: "Error: ".concat(err.status),
        message:
          "Please refresh the page. If the issue persists, please contact support.",
      },
    });
  }

  if (err.customError) {
    return setErrorAction({
      details: {
        title: "Error: ".concat(err.status),
        message: R.isNil(err.errorMessage)
          ? "An error has occurred. Please contact support."
          : err.errorMessage,
      },
    });
  }
  return setErrorAction({
    details: {
      title: "Request Failed",
      message: "An error has occurred. Please contact support.",
    },
  });
};
