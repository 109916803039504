import { ActionsObservable, combineEpics } from "redux-observable";
import { defer } from "rxjs";
import { concatMap, switchMap } from "rxjs/operators";
import { ofType } from "../reduxObservableUtils";
import { Dependancies } from "../storeTypes";
import * as io from "io-ts";
import { pipe } from "fp-ts/lib/function";
import { match } from "fp-ts/lib/TaskEither";
import { dispatchNetworkError } from "./errorHandler";
import { SpreadOverride, spreadOverrideArrayDecoder } from '../decoders';

export const key = "spreadOverrides";


const getSpreadOverridesLoadingType = "user/GET_SPREADOVERRIDES/LOADING";
const getSpreadOverridesSuccessType = "user/GET_SPREADOVERRIDES/LOADED";
const getSpreadOverridesFailedType = "user/GET_SPREADOVERRIDES/FAILED";

const addSpreadOverridesLoadingType = "user/ADD_SPREADOVERRIDES/LOADING";
const addSpreadOverridesSuccessType = "user/ADD_SPREADOVERRIDES/LOADED";
const addSpreadOverridesFailedType = "user/ADD_SPREADOVERRIDES/FAILED";

export const getSpreadOverridesLoadingAction = (groupId: number) =>
({
  type: getSpreadOverridesLoadingType, groupId
} as const);

export const getSpreadOverridesSuccessAction = (data: SpreadOverride[]) =>
  ({ type: getSpreadOverridesSuccessType, data } as const);

export const getSpreadOverridesFailedAction = {
  type: getSpreadOverridesFailedType,
} as const;

export const addSpreadOverridesLoadingAction = (
  spreadOverrideList: SpreadOverride[],
  groupId: number
  ) =>
({
  type: addSpreadOverridesLoadingType, 
  spreadOverrideList,
  groupId
} as const);

export const addSpreadOverridesSuccessAction = (spreadOverrides: SpreadOverride[]) =>
  ({ type: addSpreadOverridesSuccessType, spreadOverrides } as const);

export const addSpreadOverridesFailedAction = {
  type: addSpreadOverridesFailedType,
} as const;





type Action =
  | typeof getSpreadOverridesFailedAction
  | typeof addSpreadOverridesFailedAction

  | ReturnType<
    | typeof getSpreadOverridesLoadingAction
    | typeof getSpreadOverridesSuccessAction
    | typeof addSpreadOverridesLoadingAction
    | typeof addSpreadOverridesSuccessAction
  >;

export type State =
  | { tag: "initial" }
  | { tag: "loading" }
  | {
      tag: "loaded";
      data: SpreadOverride[];
    }
  | { tag: "error" };

const initialState: State = { tag: "initial" };

export const reducer = (state: State = initialState, action:any): State => {
  switch (action.type) {
    case getSpreadOverridesLoadingType:
      return {
        ...state,
        tag: "loading"
      }
    case getSpreadOverridesSuccessType: 
      return {
        tag: "loaded",
        data: action.data
      } 
    default:
      return state;
  }
  }

  const spreadOverridesEpic = (
    action$: ActionsObservable<Action>,
    _: any,
    deps: Dependancies
  ) =>
    action$.pipe(
      ofType(getSpreadOverridesLoadingType),
      concatMap((x) =>
        pipe(
          deps.request.get(
            `spreadOverrides/${x.groupId}`,
            spreadOverrideArrayDecoder
          ),
          match(dispatchNetworkError, (spreadOverrides: any) => {
            return getSpreadOverridesSuccessAction(spreadOverrides);
          }),
          defer
        )
      )
    );

    const addSpreadOverridesEpic = (
      action$: ActionsObservable<Action>,
      _: any,
      deps: Dependancies
    ) =>
      action$.pipe(
        ofType(addSpreadOverridesLoadingType),
        switchMap((x) =>
          pipe(
            deps.request.post(
              `spreadOverrides/${x.groupId}`,
              x.spreadOverrideList,
              io.unknown
            ),
            match(dispatchNetworkError, () => getSpreadOverridesLoadingAction(x.groupId)),
            defer
          )
        )
      );

export const epic = combineEpics(spreadOverridesEpic, addSpreadOverridesEpic);
const baseSelector: (a: any) => State = s => s[key]
export const Selectors = {
    all: baseSelector
  };