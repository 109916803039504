import { ActionsObservable, combineEpics } from "redux-observable";
import { defer } from "rxjs";
import { concatMap, switchMap } from "rxjs/operators";
import { ofType } from "../reduxObservableUtils";
import { Dependancies } from "../storeTypes";
import * as io from "io-ts";
import { pipe } from "fp-ts/lib/function";
import { match } from "fp-ts/lib/TaskEither";
import { dispatchNetworkError } from "./errorHandler";
import { User, userArrayDecoder } from "../decoders";

export const key = "users";

const addUsers = 'users/ADD_USER';

const addUserListLoadingType = "users/ADD_USERS/LOADING";
const addUserListSuccessType = "users/ADD_USERS/SUCCESS";
const addUserListFailedType = "users/ADD_USERS/FAILED";

const getUserListLoadingType = "user/GET_USERS/LOADING";
const getUserListSuccessType = "user/GET_USERS/LOADED";
const getUserListFailedType = "user/GET_USERS/FAILED";

export const getUserListLoadingAction = () =>
({
  type: getUserListLoadingType,
} as const);

export const getUserListSuccessAction = (data: User[]) =>
  ({ type: getUserListSuccessType, data } as const);

export const getUserListFailedAction = {
  type: getUserListFailedType,
} as const;


export const addUserListLoadingAction = (
  userList: any[],
  ) =>
({
  type: addUserListLoadingType, 
  userList,
} as const);

export const addUserListSuccessAction = (userList: User[]) =>
  ({ type: addUserListSuccessType, userList } as const);

export const addUserListFailedAction = {
  type: addUserListFailedType,
} as const;



type Action =
  | typeof getUserListFailedAction
  | typeof addUserListFailedAction

  | ReturnType<
    | typeof getUserListLoadingAction
    | typeof getUserListSuccessAction
    | typeof addUserListLoadingAction
    | typeof addUserListSuccessAction
  >;



export type State =
  | { tag: "initial" }
  | { tag: "loading" }
  | {
      tag: "loaded";
      data: User[];
    }
  | { tag: "error" };

const initialState: State = { tag: "initial" };

export const reducer = (state: State = initialState, action:any): State => {
  switch (action.type) {
    case getUserListLoadingType:
      return {
        ...state,
        tag: "loading"
      }
    case getUserListSuccessType: 
      return {
        tag: "loaded",
        data: action.data
      } 
    default:
      return state;
  }
}

const usersEpic = (
  action$: ActionsObservable<Action>,
  _: any,
  deps: Dependancies
) =>
  action$.pipe(
    ofType(getUserListLoadingType),
    concatMap((x) =>
      pipe(
        deps.request.get(
          `/Users`,
          userArrayDecoder
        ),
        match(dispatchNetworkError, (userData: any) => {
          return getUserListSuccessAction(userData);
        }),
        defer
      )
    )
  );

  const addUserListEpic = (
    action$: ActionsObservable<Action>,
    _: any,
    deps: Dependancies
  ) =>
    action$.pipe(
      ofType(addUserListLoadingType),
      switchMap((x) =>
        pipe(
          deps.request.post(
            `users`,
            x.userList,
            io.unknown
          ),
          match(dispatchNetworkError, () => getUserListLoadingAction()),
          defer
        )
      )
    );


export const epic = combineEpics(usersEpic, addUserListEpic);
const baseSelector: (a: any) => State = s => s[key]
export const Selectors = {
    all: baseSelector
  };