import { ActionsObservable, combineEpics } from "redux-observable";
import { defer } from "rxjs";
import { concatMap } from "rxjs/operators";
import { ofType } from "../reduxObservableUtils";
import { Dependancies } from "../storeTypes";
import * as io from "io-ts";
import { pipe } from "fp-ts/lib/function";
import { match } from "fp-ts/lib/TaskEither";
import { dispatchNetworkError } from "./errorHandler";
import { Product } from "../decoders";


export const key = "products";


const getProductsLoadingType = "user/GET_PRODUCTS/LOADING";
const getProductsSuccessType = "user/GET_PRODUCTS/LOADED";
const getProductsFailedType = "user/GET_PRODUCTS/FAILED";


export const getProductsLoadingAction = () =>
({
  type: getProductsLoadingType
} as const);

export const getProductsSuccessAction = (products: Product[]) =>
  ({ type: getProductsSuccessType, products } as const);

export const getProductsFailedAction = {
  type: getProductsFailedType,
} as const;

type Action =
  | typeof getProductsFailedAction

  | ReturnType<
    | typeof getProductsLoadingAction
    | typeof getProductsSuccessAction
  >;


  export function reducer(state=[], action:any) {
    switch (action.type) {
      
      case getProductsSuccessType: 
      {
        return action.products
      }
      default:
        return state;
    }
  }

  const productsEpic = (
    action$: ActionsObservable<Action>,
    _: any,
    deps: Dependancies
  ) =>
    action$.pipe(
      ofType(getProductsLoadingType),
      concatMap((x) =>
        pipe(
          deps.request.get(
            `/returnProducts`,
            io.unknown
          ),
          match(dispatchNetworkError, (products: any) => {
            return getProductsSuccessAction(products);
          }),
          defer
        )
      )
    );
    
export const epic = combineEpics(productsEpic);
const baseSelector: (a: any) => any = s => s[key]
export const Selectors = {
    all: baseSelector
    };