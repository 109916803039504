import React from 'react';
import styles from "./trafficLight.module.scss";

const colors = {
    red: {
      backgroundColor: "#cc3232"
    },
    yellow: {
      backgroundColor: "#e7b416"
    },
    green: {
      backgroundColor: "#2b9217"
    },
    grey: {
      backgroundColor: "grey"
    }
  };

const TrafficLight = (
    props: {
      color: string,
    }
  ) => {
    return (
      <div 
        className={styles.circle} 
        style={props.color == "red" ? colors.red : props.color == "yellow" ? colors.yellow : props.color == "green" ? colors.green : colors.grey}>
      </div>
      
    );
  };

export default TrafficLight;