import {
  ActionsObservable,
  StateObservable,
  ofType,
  combineEpics,
} from "redux-observable";
import { Dependancies } from "../storeTypes";
import { filter, map, switchMap, switchMapTo, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import IdTokenVerifier from "idtoken-verifier";
import { User } from "@auth0/auth0-spa-js";
import { clearPathname, getPathnameFromStorage } from "../../authHelpers";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as T from "fp-ts/lib/Task";
import * as TE from "fp-ts/lib/TaskEither";
import * as E from "fp-ts/lib/Either";

export const key = "auth";

const verifier = new IdTokenVerifier({});

type State =
  | { tag: "initial" }
  | { tag: "login-required" }
  | { tag: "login-failed"; error?: string }
  | { tag: "authenticated"; profile: User & { accessToken: any } };

export const initAction = { type: "INIT" } as const;
export const handleRedirectAction = {
  type: "management/authentication/HANDLE_REDIRECT",
} as const;
const checkSessionFailedAction = {
  type: "management/authentication/CHECK_SESSION_FAILED",
} as const;

export const authenticatedActionType =
  "management/authentication/AUTHENTICATED";
const authenticatedAction = (profile: User & { accessToken: any }) =>
  ({
    type: authenticatedActionType,
    profile,
  } as const);

export const loginAction = {
  type: "management/authentication/LOGIN",
} as const;

const loginFailedAction = (error?: string) =>
  ({
    type: "management/authentication/LOGIN_FAILED",
    error,
  } as const);

export const logoutAction = {
  type: "management/authentication/LOGOUT",
} as const;

type Action =
  | typeof initAction
  | typeof handleRedirectAction
  | typeof checkSessionFailedAction
  | ReturnType<typeof authenticatedAction | typeof loginFailedAction>;

export function reducer(
  state: State = { tag: "initial" },
  action: Action
): State {
  switch (action.type) {
    case initAction.type:
      return { tag: "initial" };
    case "management/authentication/CHECK_SESSION_FAILED":
      return { tag: "login-required" };
    case authenticatedActionType:
      return { tag: "authenticated", profile: action.profile };
    case "management/authentication/LOGIN_FAILED":
      return { tag: "login-failed", error: action.error };
    case handleRedirectAction.type:
    default:
      return state;
  }
}

const applyLoginCallback = (deps: Dependancies) => {
  const pushUrl = (url: string, state?: any) =>
    T.fromIO(() => deps.history.push(url, state));

  return pipe(
    whenTE(
      window.location.search.includes("error"),
      pipe(
        pushUrl("/Login", window.location.search),
        T.chainFirst(() => T.never)
      )
    ),
    TE.chain(() =>
      whenTE(window.location.search.includes("code"), () =>
        deps.auth.handleRedirectCallback().then(pushUrl("/"))
      )
    )
  );
};

const ensureUserAuthentication = (deps: Dependancies) =>
  pipe(
    deps.authHelpers.getTokenSilently,
    TE.map((token) => verifier.decode(token).payload),
    TE.chain((accessToken) =>
      pipe(
        deps.authHelpers.getUser,
        TE.map((user) => ({
          ...user,
          accessToken,
        }))
      )
    )
  );
function applyRedirects(deps: Dependancies) {
  const pushUrl = (url: string) => T.fromIO(() => deps.history.push(url));

  return pipe(
    getPathnameFromStorage,
    T.chain(O.fold(() => T.of({}), pushUrl)),
    T.chainFirst(() => clearPathname)
  );
}

export function initEpic(
  action$: ActionsObservable<any>,
  _: StateObservable<any>,
  deps: Dependancies
) {
  return action$.pipe(
    ofType(initAction.type),
    filter(() => !window.location.href.includes("Login")),
    switchMap(applyLoginCallback(deps)),
    switchMap(
      pipe(
        ensureUserAuthentication(deps),
        TE.chainFirst(() => TE.fromTask(applyRedirects(deps)))
      )
    ),
    map(E.fold(() => checkSessionFailedAction as Action, authenticatedAction))
  );
}

export function checkSessionFailedEpic(
  action$: ActionsObservable<any>,
  _: StateObservable<any>,
  deps: Dependancies
) {
  return action$.pipe(
    ofType(checkSessionFailedAction.type),
    switchMap(deps.authHelpers.loginWithRedirect),
    switchMapTo(EMPTY)
  );
}

export function loginEpic(
  action$: ActionsObservable<any>,
  _: StateObservable<any>,
  deps: Dependancies
) {
  return action$.pipe(
    ofType(loginAction.type),
    switchMap(deps.authHelpers.loginWithRedirect),
    switchMapTo(EMPTY)
  );
}

export function logoutEpic(
  action$: ActionsObservable<any>,
  _: StateObservable<any>,
  deps: Dependancies
) {
  return action$.pipe(
    ofType(logoutAction.type),
    tap(() =>
      deps.auth.logout({ logoutParams: { returnTo: `${window.location.origin}` }})
    ),
    switchMapTo(EMPTY)
  );
}

export const epic = combineEpics(
  initEpic,
  checkSessionFailedEpic,
  loginEpic,
  logoutEpic
);

const baseSelector = (state: any) => state[key] as State;
const userNameSelector = (state: any) => state[key].profile.name as State;
export const Selectors = {
  auth: baseSelector,
  userName: userNameSelector
};

function whenTE<E>(condition: boolean, action: TE.TaskEither<E, void>) {
  return condition ? action : TE.right(void 0);
}
