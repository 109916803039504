import { ActionsObservable, combineEpics } from "redux-observable";
import { defer } from "rxjs";
import { concatMap, switchMap } from "rxjs/operators";
import { ofType } from "../reduxObservableUtils";
import { Dependancies } from "../storeTypes";
import * as io from "io-ts";
import { pipe } from "fp-ts/lib/function";
import { match } from "fp-ts/lib/TaskEither";
import { dispatchNetworkError } from "./errorHandler";
import { Group, groupArrayDecoder } from "../decoders";

export const key = "group";

const addGroup = 'group/ADD_USER';

const addGroupsLoadingType = "group/ADD_GROUPS/LOADING";
const addGroupsSuccessType = "group/ADD_GROUPS/SUCCESS";
const addGroupsFailedType = "group/ADD_GROUPS/FAILED";

const getGroupsLoadingType = "user/GET_GROUPS/LOADING";
const getGroupsSuccessType = "user/GET_GROUPS/LOADED";
const getGroupsFailedType = "user/GET_GROUPS/FAILED";

export const getGroupsLoadingAction = () =>
({
  type: getGroupsLoadingType
} as const);

export const getGroupsSuccessAction = (data: Group[]) =>
  ({ type: getGroupsSuccessType, data } as const);

export const getGroupsFailedAction = {
  type: getGroupsFailedType,
} as const;


export const addGroupsLoadingAction = (
  groupList: Group[]

  ) =>
({
  type: addGroupsLoadingType, 
  groupList
} as const);

export const addGroupsSuccessAction = (Groups: Group[]) =>
  ({ type: addGroupsSuccessType, Groups } as const);

export const addGroupsFailedAction = {
  type: addGroupsFailedType,
} as const;



type Action =
  | typeof getGroupsFailedAction
  | typeof addGroupsFailedAction

  | ReturnType<
    | typeof getGroupsLoadingAction
    | typeof getGroupsSuccessAction
    | typeof addGroupsLoadingAction
    | typeof addGroupsSuccessAction
  >;


  export type State =
    | { tag: "initial" }
    | { tag: "loading" }
    | {
        tag: "loaded";
        data: Group[];
      }
    | { tag: "error" };

  const initialState: State = { tag: "initial" };

  export const reducer = (state: State = initialState, action:any): State => {
    switch (action.type) {
      case getGroupsLoadingType:
        return {
          ...state,
          tag: "loading"
        }
      case getGroupsSuccessType: 
        return {
          tag: "loaded",
          data: action.data
        } 
      default:
        return state;
    }
  }

const groupEpic = (
  action$: ActionsObservable<Action>,
  _: any,
  deps: Dependancies
) =>
  action$.pipe(
    ofType(getGroupsLoadingType),
    concatMap((x) =>
      pipe(
        deps.request.get(
          `groups`,
          io.unknown
        ),
        match(dispatchNetworkError, (data: any) => {
          return getGroupsSuccessAction(data);
        }),
        defer
      )
    )
  );

  const addGroupsEpic = (
    action$: ActionsObservable<Action>,
    _: any,
    deps: Dependancies
  ) =>
    action$.pipe(
      ofType(addGroupsLoadingType),
      switchMap((x) =>
        pipe(
          deps.request.post(
            `groups`,
            x.groupList,
            io.unknown
          ),
          match(dispatchNetworkError, () => getGroupsLoadingAction()),
          defer
        )
      )
    );


export const epic = combineEpics(groupEpic, addGroupsEpic);
const baseSelector: (a: any) => State = s => s[key]
export const Selectors = {
    all: baseSelector
  };