import { ActionsObservable, combineEpics } from "redux-observable";
import { defer } from "rxjs";
import { concatMap } from "rxjs/operators";
import { ofType } from "../reduxObservableUtils";
import { Dependancies } from "../storeTypes";
import * as io from "io-ts";
import { pipe } from "fp-ts/lib/function";
import { match } from "fp-ts/lib/TaskEither";
import { dispatchNetworkError } from "./errorHandler";
import { MarketConfig, livePricesConfigDecoder, postResponseDecoder } from "../decoders";

export const key = "marketConfig";

//********get ********/

const marketConfigLoadingType = "marketConfig/LOADING";
const marketConfigSubmittedType = "marketConfig/SUBMITTED";
const marketConfigErrorType = "marketConfig/ERROR";

export const marketConfigLoadingAction = (
) =>
  ({
    type: marketConfigLoadingType,
  } as const);


const marketConfigSuccessAction = (marketConfigData: any) =>
({
  type: marketConfigSubmittedType,
  marketConfigData
} as const);
  

const marketConfigFailureAction = {
  type: marketConfigErrorType,
} as const;

//********post********/

const marketConfigPostType = "marketConfig/POST_LOADING";
const marketConfigPostSuccessType = "marketConfig/POST_SUBMITTED";
const marketConfigPostErrorType = "marketConfig/POST_ERROR";

export const marketConfigPostAction = (configData: MarketConfig) =>
    ({
      type: marketConfigPostType,
      configData
    } as const);
  
  
const marketConfigPostSuccessAction = (marketConfigData: any) =>
  ({
    type: marketConfigPostSuccessType
  } as const);
  
const marketConfigPostFailureAction = {
  type: marketConfigPostErrorType,
} as const; 

export type Action =
  | typeof marketConfigFailureAction
  | typeof marketConfigPostFailureAction
  | ReturnType<
      | typeof marketConfigLoadingAction
      | typeof marketConfigSuccessAction
      | typeof marketConfigPostAction
      | typeof marketConfigPostSuccessAction
    >;

 export type State =
  | { tag: "initial" }
  | { tag: "loading" }
  | { tag: "loaded", data: any[]}
  | { tag: "error" };

const initialState: State = { tag: "initial" };

export function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case marketConfigSubmittedType:
      return { tag: "loaded", data: action.marketConfigData };
    case marketConfigPostType:
    case marketConfigLoadingType:
      return { tag: "loading" };
    case marketConfigErrorType:
    case marketConfigPostErrorType:
      return { tag: "error" };
    case marketConfigPostSuccessType:
      return { tag: "loaded" };
    default:
      return state;
  }
}

const marketConfigEpic = (
  action$: ActionsObservable<Action>,
  _: any,
  deps: Dependancies
) =>
  action$.pipe(
    ofType(marketConfigLoadingType),
    concatMap(() =>
      pipe(
        deps.request.get(
          `ReturnUserConfig?key=userSettings`,
          livePricesConfigDecoder
        ),
        match(dispatchNetworkError, (marketConfigData) => {
          return marketConfigSuccessAction(marketConfigData);
        }),
        defer
      )
    )
  );

const marketPostConfigEpic = (
    action$: ActionsObservable<Action>,
    _: any,
    deps: Dependancies
  ) =>
    action$.pipe(
      ofType(marketConfigPostType),
      concatMap((x) =>
        pipe(
          deps.request.post(
            `saveUserConfig`,
            x.configData,
            postResponseDecoder
          ),
          match(dispatchNetworkError, (marketConfigData) => {
            return marketConfigLoadingAction();
          }),
          defer
        )
      )
    );
    
export const epic = combineEpics(marketConfigEpic, marketPostConfigEpic);
const baseSelector: (a: any) => State = s => s[key]
export const Selectors = {
    all: baseSelector
  };