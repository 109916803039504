import { ActionsObservable, combineEpics } from "redux-observable";
import { defer } from "rxjs";
import { concatMap } from "rxjs/operators";
import { ofType } from "../reduxObservableUtils";
import { Dependancies } from "../storeTypes";
import * as io from "io-ts";
import { pipe } from "fp-ts/lib/function";
import { match } from "fp-ts/lib/TaskEither";
import { dispatchNetworkError } from "./errorHandler";
import { User, userDecoder } from "../decoders";

const userLoadingType = "user/LOADING";
const userSubmittedType = "user/SUBMITTED";
const userErrorType = "user/ERROR";

export const key = "user";

export const userLoadingAction = (
) =>
  ({
    type: userLoadingType,
  } as const);

const userFailureAction = {
  type: userErrorType,
} as const;

const userSuccessAction = (userData: any) =>
  ({
    type: userSubmittedType,
    userData
  } as const);
  
export type Action =
  | typeof userFailureAction
  | ReturnType<
      | typeof userLoadingAction
      | typeof userSuccessAction
    >;

type State =
  | { tag: "initial" }
  | { tag: "loading" }
  | { tag: "submitted" }
  | { tag: "error" };

export const initialState: State = { tag: "initial" };

export function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case userSubmittedType:
      return { tag: "submitted", data: action.userData };
    case userLoadingType:
      return { tag: "loading" };
    case userErrorType:
      return { tag: "error" };
    default:
      return state;
  }
}

const userEpic = (
  action$: ActionsObservable<Action>,
  _: any,
  deps: Dependancies
) =>
  action$.pipe(
    ofType(userLoadingType),
    concatMap((x) =>
      pipe(
        deps.request.get(
          `singleUser`,
          userDecoder
        ),
        match(dispatchNetworkError, (userData) => {
          return userSuccessAction(userData);
        }),
        defer
      )
    )
  );

export const epic = combineEpics(userEpic);

const userDataSelector = (s: any): User => s[key].data;

export const Selectors = {
  all: (s: any): State => s[key],
  data: userDataSelector
};
