import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import styles from "./styles/main.module.scss";
import ErrorModal from "./components/errorModal/view";
import CustomPrompt from "./components/customPrompt/view";
import Header from "./header/view";
import Unauthorised from "./unauthorised/view";
import MarketConfiguration from "./marketConfiguration/view";
import SpreadConfiguration from "./spreadConfiguration/view";
import MarketPrices from "./marketPrices/view";
import TransactionLog from "./transactionLog/view";
import { Selectors as userSelector, userLoadingAction } from "./redux/modules/user";
import { useDispatch, useSelector } from "react-redux";
import { pathOr } from "ramda"

const Component = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector.all);
  useEffect(() => {
    dispatch(userLoadingAction());
  }, []);
  return (
    <Switch>
      <Route exact path="/" component={MarketPrices} />
      <Route path="/MarketPrices" component={MarketPrices} />
      <Route path="/Unauthorized" component={Unauthorised} />
      <Route path="/MarketConfiguration" component={MarketConfiguration} />
      {pathOr(null, ["data", "rights"], user) === "RW" ? <Route path="/TransactionLog" component={TransactionLog} /> :<></>}
      {pathOr(false, ["data", "isAdmin"], user) ? <Route path="/SpreadConfiguration" component={SpreadConfiguration} />:<></>}
    </Switch>
  );
};

const Main = () => {
  return (
    <div className={styles.holder}>
      <Header />
      <div className={styles.mainContainer}>
        <Component />
      </div>
      <CustomPrompt />
      <ErrorModal />
    </ div>
  );
};

export default Main;
