import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import {
  Selectors as ErrorHandlerSelector,
  clearErrorAction,
  DetailsType,
} from "../../redux/modules/errorHandler";
import styles from "./errorModal.module.scss";

type errModalType = {
  error?: boolean;
  details?: DetailsType | null;
  clearErrorAction?: any;
};

const ErrorModal = ({
  error = false,
  details = null,
  clearErrorAction,
}: errModalType) => {
  return (
    <>
      {R.when(R.equals(true), () => (
        <div className={`${styles.errorModal}`}>
          <div className={styles.overlay} onClick={() => clearErrorAction()} />
          <div className={styles.modalContainer}>
            <div className={styles.modalContent}>
              <h6 className={styles.header}>
                {R.pathOr(null, ["title"], details)}
              </h6>
              <div className={styles.body}>
                {R.pathOr(null, ["message"], details)}
              </div>
              <div className={styles.footer}>
                <button onClick={() => clearErrorAction()}>Close</button>
              </div>
            </div>
          </div>
        </div>
      ))(error && !R.isNil(details))}
    </>
  );
};

export default connect(ErrorHandlerSelector.all, { clearErrorAction })(
  ErrorModal
);
