import { useDispatch, useSelector } from "react-redux";
import { addSpreadOverridesLoadingAction, getSpreadOverridesLoadingAction, Selectors as spreadOverridesSelector } from "../redux/modules/spreadOverrides";
import { useEffect, useState } from "react";
import { Product, SpreadOverride, EmSpread, MarketGroup } from "../redux/decoders";
import { append, compose, concat, filter, not, pathOr, pipe, propEq } from "ramda";
import { getMarketGroupsLoadingAction, Selectors as marketGroupsSelector } from "../redux/modules/marketGroups";
import { getProductsLoadingAction, Selectors as productsSelector } from "../redux/modules/products";
import { getEmSpreadsLoadingAction, Selectors as emSpreadSelector } from "../redux/modules/emSpreads";
import styles from "./spreadConfiguration.module.scss";
import { foldTag } from "../utils/fold";
import React from "react";


export const SpreadOverridesTable = (props:
    {
      selectedGroup: any,
      setSaveFlag: any,
      setSaveTable: any
    }) => { 
        const dispatch = useDispatch();
        useEffect(() => {
          setSpreadOverrideList(pathOr([], ["data"], spreadOverridesList))
          dispatch(getSpreadOverridesLoadingAction(pathOr(0, ["selectedGroup","group_Id"], props)))
          dispatch(getMarketGroupsLoadingAction(pathOr(0, ["selectedGroup","group_Id"], props)))
          dispatch(getProductsLoadingAction())
          dispatch(getEmSpreadsLoadingAction(pathOr(0, ["selectedGroup","group_Id"], props)))
        }, []);

        const spreadOverridesList = useSelector(
            spreadOverridesSelector.all
        );

        const marketGroupsList = useSelector(
          marketGroupsSelector.all
        );

        const productsList: Product[] = useSelector(
          productsSelector.all
        );

        const emSpreadList: EmSpread[] = useSelector (
          emSpreadSelector.all
        );


        const [spreadOverrideList, setSpreadOverrideList] = useState<SpreadOverride[]>(pathOr([], ["data"], spreadOverridesList));
        const [marketGroupList, setMarketGroupList] = useState<MarketGroup[]>(pathOr([], ["data"], marketGroupsList));

        const updateEntry = ( keyName: string, newValue: any, market_Id: string, product_Id: string, ) => {
          const newState = spreadOverrideList.map(obj => {
            
            if (obj.market_Id === market_Id && obj.product_Id === product_Id) {
              switch(keyName) {
                case "market_Id":
                  return {...obj, market_Id: newValue};
                case "product_Id":
                  return {...obj, product_Id: newValue};
                case "bid_Spread":
                  return {...obj, bid_Spread: newValue};
                case "ask_Spread":
                  return {...obj, ask_Spread: newValue};
                default:
                  return {...obj};
              } 
            }
            return obj;
          });
      
          setSpreadOverrideList(newState);
          props.setSaveTable(newState)
          props.setSaveFlag(true)
        };

        const deleteEntry = ( market_Id: string, product_Id: string, ) => {

          const newState = filter(
            compose(
             not,
             propEq('market_Id', market_Id) && propEq('product_Id', product_Id)
            ),
            spreadOverrideList,
          )
      
          setSpreadOverrideList(newState);
          props.setSaveTable(newState)
          props.setSaveFlag(true)
        };
        useEffect(() => {
          setSpreadOverrideList(pathOr([], ["data"], spreadOverridesList))
        }, [spreadOverridesList]);
        return(
          <>
            <button 
              className={styles.addNewButton}
              onClick={() => {
                setSpreadOverrideList(append(spreadOverrideDefault, spreadOverrideList))
                
                }}>
                <span className={styles.plusMinusIcon}>+</span> Add New Overrides
            </button>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Market</th>
                  <th>Tenor</th>
                  <th>EM Bid</th>
                  <th>EM Ask</th>
                  <th>Bid</th>
                  <th>Ask</th>
                  <th>Total Bid</th>
                  <th>Total Ask</th>
                </tr>
              </thead>
              <tbody>
              {foldTag(spreadOverridesList, {
                error: () => null,
                loading: () => (
                  <tr className={styles.tableLoadingContainer}>
                    <td colSpan={9} style={{ textAlign: "center" }}>
                    <div
                      style={{ width: "100%", height: "100%" }}
                      className="fa fa-spinner fa-spin"
                    ></div>
                  </td>
                  </tr>),
                initial: () => null,
                loaded: (loaded) => {

                  return (
                    <>
                    {spreadOverrideList.map(
                      (spreadOverrides: any, index: number) => {

                        return pathOr(0, ["group_Id"], spreadOverrides) == pathOr(0, ["selectedGroup", "group_Id"], props) ?
                          <tr key={index + "-market_Id"}>
                            <td
                              className={styles.plusMinusIcon}
                              onClick={() => deleteEntry(
                                spreadOverrides.market_Id,
                                spreadOverrides.product_Id)}>
                              −
                            </td>
                            <td>
                              <select onChange={e => updateEntry(
                                      "market_Id",
                                      e.target.value,
                                      spreadOverrides.market_Id,
                                      spreadOverrides.product_Id 
                                    )}
                                    value={spreadOverrides.market_Id}
                                    >
                                {foldTag(marketGroupsList, {
                                  error: () => null,
                                  loading: () => null,
                                  initial: () => null,
                                  loaded: () => {
                                    return (
                                      <>
                                        {marketGroupList.map(
                                          (marketGroup: any, index: number) => (
                                            <option key={index + "-marketGroup"} value={marketGroup.market_Name}>{marketGroup.market_Name}
                                            </option>
                                          )
                                        )
                                        }
                                      </>)
                                    }
                                })}
                                <option  hidden>{spreadOverrides.market_Id}</option>
                              </select>
                            </td>
                            <td>
                              <select onChange={e => updateEntry(
                                      "product_Id",
                                      e.target.value,
                                      spreadOverrides.market_Id,
                                      spreadOverrides.product_Id 
                                    )}
                                    value={spreadOverrides.product_Id}
                                    >
                                {productsList.map(
                                  (products: any, index: number) => (
                                    <option key={index+ "-product"} value={products.product}>{products.product}
                                    </option>
                                  )
                                )
                                }
                                <option  hidden>{spreadOverrides.product_Id}</option>
                                
                              </select>
                            </td>
                            {spreadOverrides.market_Id == "" ?
                            <>
                              <td></td> 
                              <td></td>
                            </> :
                            emSpreadList.map(
                                (emSpread: any, index: number ) => (
                                  <React.Fragment key={index + "-emSpread"}>
                                  {emSpread.market_ID == spreadOverrides.market_Id ? 
                                  <>
                                    <td>{emSpread.bid_Spread}</td> 
                                    <td>{emSpread.ask_Spread}</td>
                                  </>
                                  :
                                  <></>}
                                  </React.Fragment>
                                )
                              )
                            }
                            <td>
                              <input
                                type= "number"
                                defaultValue={loaded.data.find(obj => obj.market_Id === spreadOverrides.market_Id)?.bid_Spread!}
                                onChange={e => updateEntry(
                                  "bid_Spread",
                                  Number(e.target.value),
                                  spreadOverrides.market_Id,
                                  spreadOverrides.product_Id 
                                )}
                              ></input>
                            </td>
                            <td>
                              <input
                                type="number"
                                defaultValue={loaded.data.find(obj => obj.market_Id === spreadOverrides.market_Id)?.ask_Spread!}
                                onChange={e => updateEntry(
                                  "ask_Spread",
                                  Number(e.target.value),
                                  spreadOverrides.market_Id,
                                  spreadOverrides.product_Id 
                                )}
                              ></input>
                            </td>
                            {emSpreadList.map(
                                (emSpread: any, index: number) => (
                                  <React.Fragment key={index + "-emSpreadList"}>
                                  {emSpread.market_ID === spreadOverrides.market_Id ? 
                                  <>
                                    <td>{((spreadOverrides.bid_Spread as number) + emSpread.bid_Spread).toFixed(2)}</td> 
                                    <td>{((spreadOverrides.ask_Spread as number) + emSpread.ask_Spread).toFixed(2)}</td>
                                  </>
                                  : <></>}
                                  </React.Fragment>
                                )
                              )
                            }
                          </tr>
                        : <></>
                      }
                    )}
                    </>
                  )
                }
              })}
              </tbody>
            </table>
        </>
        )
    }

    var spreadOverrideDefault= {
      group_Id: 1,
      market_Id: "Belgium Baseload",
      product_Id: "Cal+1",
      bid_Spread: 0,
      ask_Spread: 0
    }