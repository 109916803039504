import { ActionsObservable, combineEpics } from "redux-observable";
import { defer } from "rxjs";
import { concatMap } from "rxjs/operators";
import { ofType } from "../reduxObservableUtils";
import { Dependancies } from "../storeTypes";
import * as io from "io-ts";
import { pipe } from "fp-ts/lib/function";
import { match } from "fp-ts/lib/TaskEither";
import { dispatchNetworkError } from "./errorHandler";
import { EmSpread, emSpreadArrayDecoder } from "../decoders";


export const key = "emSpreads";


const getEmSpreadsLoadingType = "user/GET_EMSPREADS/LOADING";
const getEmSpreadsSuccessType = "user/GET_EMSPREADS/LOADED";
const getEmSpreadsFailedType = "user/GET_EMSPREADS/FAILED";


export const getEmSpreadsLoadingAction = (groupId: number) =>
({
  type: getEmSpreadsLoadingType, groupId
} as const);

export const getEmSpreadsSuccessAction = (emSpreads: EmSpread[]) =>
  ({ type: getEmSpreadsSuccessType, emSpreads } as const);

export const getEmSpreadsFailedAction = {
  type: getEmSpreadsFailedType,
} as const;

type Action =
  | typeof getEmSpreadsFailedAction

  | ReturnType<
    | typeof getEmSpreadsLoadingAction
    | typeof getEmSpreadsSuccessAction
  >;


  export function reducer(state=[], action:any) {
    switch (action.type) {
      
      case getEmSpreadsSuccessType: 
      {

        return action.emSpreads
      }
      default:
        return state;
    }
  }

  const emSpreadsEpic = (
    action$: ActionsObservable<Action>,
    _: any,
    deps: Dependancies
  ) =>
    action$.pipe(
      ofType(getEmSpreadsLoadingType),
      concatMap((x) =>
        pipe(
          deps.request.get(
            `returnEmSpreads/${x.groupId}`,
            emSpreadArrayDecoder
          ),
          match(dispatchNetworkError, (emSpreads: any) => {
            return getEmSpreadsSuccessAction(emSpreads);
          }),
          defer
        )
      )
    );
    
export const epic = combineEpics(emSpreadsEpic);
const baseSelector: (a: any) => any = s => s[key]
export const Selectors = {
    all: baseSelector
    };