import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory as createHistory } from "history";
import App from "./App";
import CreateStore from "./redux/configureStore";
import { getEnv } from "./environment";
import * as RxOp from "rxjs/operators";
import "@fortawesome/fontawesome-free/css/all.css";
import { Auth0Client, IdToken  } from "@auth0/auth0-spa-js";
import { createAuthHelpers } from "./authHelpers";
import "./services";
import { createService } from "./services";
import "./styles/fonts.scss";

const history = createHistory();

let authModule: Auth0Client | null = null;
export let signalRUrl: string | null = null;

getEnv
  .pipe(
    RxOp.map((env) => {
      authModule = new Auth0Client({
        domain: env.domain,
        clientId: env.clientID,
        authorizationParams:{
          audience: env.audience,
          redirect_uri: env.callbackUrl,
          scope: "openid profile email"
        },
        useRefreshTokens: true,
        cacheLocation: "localstorage"
      });

      const authHelpers = createAuthHelpers(authModule);
      signalRUrl = env.serviceUrl + "/signalr/";
      return CreateStore({
        auth: authModule,
        authHelpers,
        env,
        history,
        request: createService({
          baseUrl: env.serviceUrl,
          authHelpers,
        }),
      });
    })
  )
  .subscribe((store) => {
    const render = (Component: any) => {
      ReactDOM.render(
        <React.StrictMode>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <Component />
            </ConnectedRouter>
          </Provider>
        </React.StrictMode>,
        document.getElementById("root")
      );
    };

    if (module.hot) {
      module.hot.accept("./App.tsx", () => {
        render(App);
      });
    }
    render(App);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


export const getAccessToken = async () => {
  const isAuthenticated = await authModule?.isAuthenticated();

  if (isAuthenticated) {
    const idTokenClaims = await authModule?.getIdTokenClaims();
    const idToken = idTokenClaims ? idTokenClaims.__raw : null;
    //return idToken;
    const accessToken = await authModule?.getTokenSilently();
    return accessToken;
  } else {
    return null;
  }
}