import * as io from "io-ts";

export const groupDecoder = io.type({
    group_Id: io.number,
    group_Name: io.string,
    lot_Size: io.number,
    transcaction_Type: io.string,
})

export const groupArrayDecoder = io.array(groupDecoder)

export const userDecoder = io.type({
    email: io.union([io.string, io.null]),
    name: io.union([io.string, io.null]),
    group_Id: io.union([io.number, io.null]),
    rights: io.union([io.string, io.null]),
    group: io.union([io.string, io.null]),
    type: io.union([io.string, io.null]),
    lot_Size: io.union([io.number, io.null]),
    isAdmin: io.boolean,
    company: io.union([io.string, io.null]),
    emailTo: io.union([io.string, io.null]),
    emailListBcc: io.union([io.string, io.null]),
  });

export const userArrayDecoder = io.array(io.union([userDecoder, io.null]))

export const spreadOverrideDecoder = io.type({
    group_Id: io.number,
    market_Id: io.union([io.string, io.null]),
    product_Id: io.union([io.string, io.null]),
    bid_Spread: io.union([io.number, io.null]),
    ask_Spread: io.union([io.number, io.null])
})

export const spreadOverrideArrayDecoder = io.array(spreadOverrideDecoder)

export const marketGroupDecoder = io.type({
    group_Id: io.number,
    market_Name: io.union([io.string, io.null]),
    available: io.boolean,
    bid_Spread: io.union([io.number, io.null]),
    ask_Spread: io.union([io.number, io.null])
})

export const marketGroupArrayDecoder = io.array(io.union([marketGroupDecoder, io.null]))

export const emSpreadDecoder = io.type({
    ask_Spread: io.union([io.number, io.null]),
    bid_Spread: io.union([io.number, io.null]),
    group_ID: io.number,
    market_ID: io.union([io.string, io.null]),
})

export const emSpreadArrayDecoder = io.array(io.union([emSpreadDecoder, io.null]))

export const productDecoder = io.type({
    product: io.union([io.string, io.null]),
})

export const productArrayDecoder = io.array(io.union([productDecoder, io.null]))

export type Group = io.TypeOf<typeof groupDecoder>;
export type User = io.TypeOf<typeof userDecoder>;
export type SpreadOverride = io.TypeOf<typeof spreadOverrideDecoder>;
export type MarketGroup = io.TypeOf<typeof marketGroupDecoder>;
export type EmSpread = io.TypeOf<typeof emSpreadDecoder>;
export type Product = io.TypeOf<typeof productDecoder>;

//** user Config **/
export const userConfigDecoder = io.type({
    id: io.string,
    vatPercent: io.number,
    name: io.string,
  });

export type UserConfig = io.TypeOf<typeof userConfigDecoder>;

//****config data */
export const marketDataConfigDecoder = io.type({
  hidden: io.array(io.union([io.string, io.null])),
  order: io.array(io.union([io.string, io.null])),
});
export const jsonDecoder = io.type({
  livePricesGrid: marketDataConfigDecoder,
});

export const configDecoder = io.type({
  key: io.union([io.string, io.null]),
  json: io.union([io.string, io.null]),
});

export const livePricesConfigDecoder = io.type({
  livePricesGrid: io.union([io.string, io.null])
});

export type MarketConfig = io.TypeOf<typeof configDecoder>;

//******** Post Response *********/
export const postResponseDecoder = io.string



//******** Market data */
export const marketDataMarketDecoder = io.type({
  ImpliedBid: io.union([io.number, io.null]),
  ImpliedAsk: io.union([io.number, io.null]),
  BestBid: io.union([io.number, io.null]),
  BestAsk: io.union([io.number, io.null]),
  LastTrade: io.union([io.number, io.null]),
  PreviousLastTrade: io.union([io.number, io.null]),
});

const marketsDecoder = io.record(io.string, marketDataMarketDecoder);

export const marketDataRowDecoder = io.type({
  Absolute: io.union([io.string, io.null]),
  Relative: io.union([io.string, io.null]),
  Kind: io.union([io.string, io.null]),
  Markets: marketsDecoder,
});

const marketDataListDecoder = io.union([io.record(io.string, marketDataRowDecoder), io.null]);

export type MarketDataRow = io.TypeOf<typeof marketDataRowDecoder>;
export type MarketDataList = io.TypeOf<typeof marketDataListDecoder>;


export const operationDecoder = io.type({
  op: io.literal('replace'), // 'op' should be 'replace'
  path: io.string,
  value: io.number,
});

export const operationListDecoder = io.array(operationDecoder)

export type OperationList = io.TypeOf<typeof operationListDecoder>;

export const signalrStatusDecoder = io.type({
  lastUpdate: io.union([io.string, io.null]), 
  state: io.string, 
});
export type  SignalrStatusType = io.TypeOf<typeof signalrStatusDecoder>;


//******************* Transaction */
const transactionDecoder = io.type({
  tradeDateTime: io.string,
  price: io.number,
  volume: io.number,
  marketName: io.string,
  absoluteProductName: io.string,
  sequence: io.number,
  direction: io.string,
});
export const transactionsDecoder = io.array(transactionDecoder);

export const closeTransactionDecoder = io.type({
  lotAmount: io.number,
  lotSize: io.number,
  type: io.string,
  price: io.number,
  productName: io.string, 
  marketName: io.string,
  direction: io.string,
  profile: io.string,
});


// Define an operationListDecoder as you've provided
export type CloseTransaction = io.TypeOf<typeof closeTransactionDecoder>;
export type Transaction = io.TypeOf<typeof transactionDecoder>;