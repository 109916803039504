import { useEffect, useState } from "react";
import styles from "./header.module.scss";
import Logo from "../logo.svg";
import AxpoLogo from "../styles/AXPO_LOGO_CMYK_NEW.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  logoutAction,
  Selectors as authSelectors,
} from "../redux/modules/authentication";
import { User } from "@auth0/auth0-spa-js";
import {
  Selectors as menuSelectors,
  userMenuClickedAction,
} from "../redux/modules/menu";

import {
  Selectors as userSelectors
} from "../redux/modules/user";

import {
  marketConfigLoadingAction
} from "../redux/modules/marketConfig";
import { userLoadingAction } from "../redux/modules/user";
import { prop, pathOr } from "ramda";
import { getGroupsLoadingAction } from "../redux/modules/group";

enum BodyOptions {
  Main = "Main",
  SwitchTenant = "SwitchTenant",
}

const HeaderView = () => {
  const state = useSelector(authSelectors.auth);
  const userName = useSelector(authSelectors.userName);
  const user: User = useSelector(userSelectors.all);
  const isUserMenuOpen = useSelector(menuSelectors.isUserMenuOpen);
  const location = useLocation();
  const history = useHistory();
  const pathName = prop("pathname", location)

  useEffect(() => {
    dispatch(userLoadingAction());
    dispatch(getGroupsLoadingAction())
    dispatch(marketConfigLoadingAction());
  }, []);
  const dispatch = useDispatch();
  return (
    <div>
      <div className={`${styles.header}`}>
        <div className={`${styles.clickOneLogo}`}>ClickOne</div>
        <div className={`${styles.logoContainer}`}>
          <img className={`${styles.axpoLogo}`} alt="AxpoLogo" src={AxpoLogo} />
        </div>
      </div>
      <div className={`${styles.subHeader}`} >
        <div className={`${styles.navIcon}`}>
          <div className="fa fa-user"></div>
          <span>{userName}</span>
        </div>
        <div className={`${styles.navIconPointer}`} onClick={() => dispatch(logoutAction)}>
          <div className="fa  fa-reply"></div>
          <span>Logout</span>
        </div>
        <div className={`${styles.navIconPointer}  ${pathName === "/MarketPrices" ? styles.navIconActive : ""}`} onClick={() => history.push("MarketPrices")}>
          <div className="fa fa-euro-sign"></div>
          <span>Market Prices</span>
        </div>

        {
          pathOr(null, ["data", "rights"], user) === "RW" ?
            <div className={`${styles.navIconPointer}  ${pathName === "/TransactionLog" ? styles.navIconActive : ""}`} onClick={() => history.push("TransactionLog")}>
              <div className="fa fa-md fa-fw fa-list-alt"></div>
              <span>Transaction Log</span>
            </div>
            : <></>
        }
        {
          pathOr(false, ["data", "isAdmin"], user) && pathOr(null, ["data", "rights"], user) === "RW" ?
            <div className={`${styles.navIconPointer} ${pathName === "/SpreadConfiguration" ? styles.navIconActive : ""}`} onClick={() => history.push("SpreadConfiguration")}>
              <div className="fa fa-md fa-fw fa-wrench"></div>
              <span>Spread Configuration</span>
            </div>
            : <></>
        }
        <div className={`${styles.navIconPointer} ${pathName === "/MarketConfiguration" ? styles.navIconActive : ""}`} onClick={() => history.push("MarketConfiguration")}>
          <div className="fa fa-md fa-fw fa-cogs"></div>
          <span>Market Configuration</span>
        </div>
      </div>
    </div>
  );
};

export default HeaderView;
