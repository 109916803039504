import React, { useEffect } from "react";

import { Prompt } from "react-router-dom";

const stepLinks = ["/MarketPrices", "/TransactionLog", "/SpreadConfiguration", "/MarketConfiguration"];


export const NavigateModal = (props:{ hasUnsavedChanges: boolean }) => {
    useEffect(() => {
      const onBeforeUnload = (e: any) => {
        if (props.hasUnsavedChanges) {
          e.preventDefault();
          e.returnValue = <></>;
        }
      };
      window.addEventListener("beforeunload", onBeforeUnload);
      return () => {
        <>
        {window.removeEventListener("beforeunload", onBeforeUnload)}
        </>
      };
    }, [props.hasUnsavedChanges]);
    
    const onLocationChange = (location: any) => {
        if (stepLinks.includes(location.pathname)) {
            if (props.hasUnsavedChanges){
                return ("This page contains unsaved changes. Would you like to continue?");
            }
        }
        return false;
      };
     
      return <Prompt when={props.hasUnsavedChanges} message={onLocationChange} />;
  };

export default NavigateModal



