import React, { useEffect, useState } from "react";
import styles from "./marketConfiguration.module.scss";
import mainStyles from "../styles/main.module.scss";
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import {
  Selectors as ConfigSelectors,
  marketConfigPostAction,
} from "../redux/modules/marketConfig";
import {
  Selectors as UserMarketsSelector,
  getUserMarketLoadingAction,
} from "../redux/modules/userMarket";
import { Selectors as userSelector } from "../redux/modules/user";
import {
  append,
  intersection,
  filter,
  pathOr,
  map,
  addIndex,
  concat,
  includes,
  not,
  propOr,
  __,
} from "ramda";
import { marketConfigLoadingAction } from "../redux/modules/marketConfig";
import { useDispatch, useSelector } from "react-redux";
import { MarketConfig } from "../redux/decoders";
import { foldTag } from "../utils/fold";
import NavigateModal from "../components/navigateModal/view";

interface ItemType {
  id: number;
  name: string;
}

const MarketConfiguration = () => {
  const dispatch = useDispatch();
  const config = useSelector(ConfigSelectors.all);
  const userMarkets = useSelector(UserMarketsSelector.all);
  const user = useSelector(userSelector.all);

  const [selectedMarkets, setSelectedMarkets] = useState<ItemType[]>([]);
  const [availableMarkets, setAvailableMarkets] = useState<ItemType[]>([]);
  const [marketOrderChanged, setMarketOrderChanged] = useState<boolean>(false);
  const [configMisaligned, setConfigMisaligned] = useState<boolean>(false);
  useEffect(() => {
    const groupId = pathOr(null, ["data", "group_Id"], user);
    if (groupId) {
      dispatch(getUserMarketLoadingAction(groupId));
    }
  }, [user]);
  useEffect(() => {
    const livePricesGrid = pathOr("{}", ["data", "livePricesGrid"])(config);
    const livePricesGridParsed = JSON.parse(livePricesGrid);
    let hidden: string[] = pathOr([], ["hidden"])(livePricesGridParsed);
    let order: string[] = pathOr([], ["order"])(livePricesGridParsed);
    const mergedList: string[] = concat(hidden, order);
    const missingMarkets: string[] = filter(
      (item: string) => not(includes(item, propOr([], "data", userMarkets))),
      mergedList
    );
    const marketsToAdd: string[] = filter(
      (item: string) => not(includes(item, mergedList)),
      propOr([], "data", userMarkets)
    );

    const availableOrderList = intersection(
      propOr([], "data", userMarkets),
      order
    );
    const hiddenOrderList = intersection(
      propOr([], "data", userMarkets),
      hidden
    );

    if (missingMarkets.length !== 0 || marketsToAdd.length !== 0)
      setConfigMisaligned(true);
    else setConfigMisaligned(false);

    hidden = concat(hiddenOrderList, marketsToAdd);
    setAvailableMarkets(
      addIndex(map)(
        (name, index) => ({ id: index + selectedMarkets.length, name }),
        hidden
      )
    );
    setSelectedMarkets(
      addIndex(map)((name, index) => ({ id: index + 1, name }), order)
    );
  }, [config, userMarkets]);

  useEffect(() => {
    dispatch(marketConfigLoadingAction());
  }, []);

  const onSelectedMarketsSortEnd = (oldIndex: number, newIndex: number) => {
    setMarketOrderChanged(true);
    setSelectedMarkets((array) => arrayMove(array, oldIndex, newIndex));
  };

  const changeArray = (
    currentArray: ItemType[],
    currentArraySetter: Function,
    moveArray: ItemType[],
    moveArraySetter: Function,
    item: ItemType
  ) => {
    if (!includes(item, moveArray)) {
      setMarketOrderChanged(true);
      moveArraySetter(append(item, moveArray));
      currentArraySetter(filter((row) => row !== item, currentArray));
    }
  };

  return (
    <>
      <div>
        <div className={styles.ribbon}>
          <div className={mainStyles.title}>Market Configuration</div>
          <button
            disabled={!marketOrderChanged && !configMisaligned}
            className={styles.saveButton}
            onClick={() => {
              setMarketOrderChanged(false);
              setConfigMisaligned(false);
              dispatch(
                marketConfigPostAction({
                  key: "userSettings",
                  json: JSON.stringify({
                    livePricesGrid: JSON.stringify({
                      hidden: map((item) => item.name, availableMarkets),
                      order: map((item) => item.name, selectedMarkets),
                    }),
                  }),
                })
              );
            }}
          >
            Save
          </button>
        </div>
        {foldTag(config, {
          error: () => null,
          loading: () => <></>,
          initial: () => <></>,
          loaded: (loadedData) => {
            return (
              <>
                {configMisaligned ? (
                  <div className={styles.configError}>
                    Config is misaligned please save to realign
                  </div>
                ) : (
                  <></>
                )}
              </>
            );
          },
        })}
        <NavigateModal hasUnsavedChanges={marketOrderChanged}></NavigateModal>
        <div className={styles.sortLists}>
          <div>
            <h2 className={styles.title}>Available</h2>
            {foldTag(config, {
              error: () => (
                <div className={styles.error}>
                  <div>error loading list</div>
                </div>
              ),
              loading: () => (
                <div className={styles.loading}>
                  <span className="fas fa-spinner fa-2x fa-spin"></span>
                </div>
              ),
              initial: () => (
                <div className={styles.loading}>
                  <span className="icon fas fa-spinner fa-2x fa-spin"></span>
                </div>
              ),
              loaded: (loadedData) => {
                return (
                  <>
                    {availableMarkets.map((item) => (
                      <div key={"avail-" + item.name}>
                        <div className={styles.availableItem}>
                          <div>
                            <i
                              className="fa fa-arrow-circle-right"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                changeArray(
                                  availableMarkets,
                                  setAvailableMarkets,
                                  selectedMarkets,
                                  setSelectedMarkets,
                                  item
                                )
                              }
                            ></i>
                            {item.name}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                );
              },
            })}
          </div>
          <div>
            <h2 className={styles.title}>Selected</h2>
            {foldTag(config, {
              error: () => (
                <div className={styles.error}>
                  <div>error loading list</div>
                </div>
              ),
              loading: () => (
                <div className={styles.loading}>
                  <span className="icon fas fa-spinner fa-2x fa-spin"></span>
                </div>
              ),
              initial: () => (
                <div className={styles.loading}>
                  <span className="icon fas fa-spinner fa-2x fa-spin"></span>
                </div>
              ),
              loaded: (loadedData) => {
                return (
                  <div className={styles.sortListContainer}>
                    <div className={styles.selectedArrow}>
                      {selectedMarkets.map((item) => (
                        <div
                          key={"avail-" + item.name}
                          className={styles.selectedArrow}
                        >
                          <div className={styles.availableItem}>
                            <i
                              className="fa fa-arrow-circle-left"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                changeArray(
                                  selectedMarkets,
                                  setSelectedMarkets,
                                  availableMarkets,
                                  setAvailableMarkets,
                                  item
                                )
                              }
                            ></i>
                          </div>
                        </div>
                      ))}
                    </div>
                    <SortableList
                      onSortEnd={onSelectedMarketsSortEnd}
                      className="list"
                      draggedItemClassName="dragged"
                    >
                      {selectedMarkets.map((item) => (
                        <SortableItem key={"selected-" + item.name}>
                          <div>
                            <div
                              className={styles.item}
                              style={{ cursor: "grab" }}
                            >
                              <div>
                                <i className="fa fa-bars"></i>
                                {item.name}
                              </div>
                              
                            </div>
                          </div>
                        </SortableItem>
                      ))}
                    </SortableList>
                  </div>
                );
              },
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketConfiguration;
