import { Auth0Client } from "@auth0/auth0-spa-js";
import * as T from "fp-ts/lib/Task";
import * as TE from "fp-ts/lib/TaskEither";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";

export function createAuthHelpers(auth: Auth0Client) {
  const getUser = TE.tryCatch(
    () => auth.getUser().then((x) => x ?? Promise.reject("user not found")),
    (x) => x as string
  );

  const loginWithRedirect = pipe(
    T.fromIO(() => auth.loginWithRedirect()),
    T.chain(() => T.never)
  );

  const getTokenSilently = pipe(
    TE.tryCatch<string, string>(
      () => auth.getTokenSilently(),
      (e: any) => e.error
    ),
    TE.orElse((error: string) =>
      error === "access_denied"
        ? TE.left("Can't acquire token")
        : pipe(
            loginWithRedirect,
            T.map(() => ""),
            (t) => TE.fromTask<string, string>(t)
          )
    )
  );

  return {
    getUser,
    getTokenSilently,
    loginWithRedirect,
  };
}

export const getPathnameFromStorage = T.fromIO(() =>
  pipe(
    localStorage.getItem("pathName"),
    O.fromNullable,
    O.filter((x) => !x.includes("Login"))
  )
);

export const storePathname = T.fromIO(() =>
  localStorage.setItem("pathName", window.location.pathname)
);

export const clearPathname = T.fromIO(() =>
  localStorage.removeItem("pathName")
);
