import React, { useEffect, useState } from "react";
import mainStyles from "../styles/main.module.scss";
import styles from "./spreadConfiguration.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { addUserListLoadingAction, getUserListLoadingAction, Selectors as userSelector } from "../redux/modules/users";
import { Selectors as groupSelector } from "../redux/modules/group";
import {
  filter,
  find,
  head,
  map,
  mergeDeepRight,
  mergeLeft,
  mergeWithKey,
  pathOr,
  pipe,
  propOr,
  values,
} from "ramda";
import { UsersTable } from "./usersTable";
import { MarketsTable } from "./marketsTable";
import { SpreadOverridesTable } from "./spreadOverridesTable";
import { MarketGroup, SpreadOverride, User, Group } from "../redux/decoders";
import { addMarketGroupsLoadingAction, getMarketGroupsLoadingAction, Selectors as marketGroupsSelector } from "../redux/modules/marketGroups";
import { addSpreadOverridesLoadingAction, getSpreadOverridesLoadingAction, Selectors as spreadOverridesSelector } from "../redux/modules/spreadOverrides";
import { Selectors as usersSelector } from "../redux/modules/users";
import { getGroupsLoadingAction, addGroupsLoadingAction, Selectors as groupSelectors } from "../redux/modules/group";
import { foldTag } from "../utils/fold";
import NavigateModal from "../components/navigateModal/view";



const SpreadConfiguration = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState("Markets");


  useEffect(() => {
    dispatch(getGroupsLoadingAction())
    dispatch(getUserListLoadingAction())
  }, []);
 
  const groupInitial: Group = {
    "group_Id": 1,
    "group_Name": "Italian Consumers 1",
    "lot_Size": 1,
    "transcaction_Type": "Buy"
  }
  
  
  const groupList = useSelector(groupSelector.all);

  const [groupArray, setGroupArray]  = useState<Group[]>(pathOr([], ["data"], groupList));
  
  const [selectedGroup, setSelectedGroup] = useState<Group>(pathOr(groupInitial, ["data", 0], groupList));
  
  useEffect(() => {
    setGroupArray(pathOr([], ["data"], groupList));
    setSelectedGroup(pathOr(groupInitial, ["data", 0 ], groupList))
}, [groupList]);

  const [saveFlag, setSaveFlag] = useState(false)

  const initialUsersList = useSelector(
    usersSelector.all
  );

  const initialMarketGroupsTable = useSelector(
    marketGroupsSelector.all
  );

  const initialSpreadOverridesList = useSelector(
    spreadOverridesSelector.all
  );
  

  const [newUsersTable, SetNewUsersTable] = useState<User[]>(pathOr([], ["data"], initialUsersList));
  const [newMarketGroupsTable, SetNewMarketGroupsTable] = useState<MarketGroup[]>(pathOr([], ["data"], initialMarketGroupsTable));
  const [newSpreadOverridesTable, setNewSpreadOverridesTable]  = useState<SpreadOverride[]>(pathOr([], ["data"], initialSpreadOverridesList));

  const setTransaction = (selection: string) => {
    setSelectedGroup(
      mergeDeepRight(selectedGroup, { transcaction_Type: selection })
    );
    updateGroupArray(
      mergeDeepRight(selectedGroup, { transcaction_Type: selection })
    );
    setSaveFlag(true)
  };


  const setLotSize = (selection: number) => {
    setSelectedGroup(
      mergeDeepRight(selectedGroup, { lot_Size: selection })
    );
    updateGroupArray(
      mergeDeepRight(selectedGroup, { lot_Size: selection })
    );
    setSaveFlag(true)
  };

  const updateGroupArray = (selectedGroup: Group) => {
    setGroupArray(
      map((group: Group) => {
        return selectedGroup.group_Name === group.group_Name
          ? selectedGroup
          : group;
      })(groupArray)
    );
  };

  const objectsEqual = (o1: any, o2: any) => Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(p => o1[p] === o2[p]);

  const setGroup = () => {
    const $select = document.querySelector(
      "#groupDropdown"
    ) as HTMLInputElement;
    var chosenGroup = pipe(
      find((x: Group) => {
        return x.group_Name === $select.value;
      })
    )(groupArray);

    updateGroupArray(chosenGroup || groupArray[0]);
    setSelectedGroup(chosenGroup || groupArray[0]);
    dispatch(getMarketGroupsLoadingAction(chosenGroup?.group_Id!))
    dispatch(getSpreadOverridesLoadingAction(chosenGroup?.group_Id!))
  };

  const saveGroupInfo = () => {
    
    page === "Users" && newUsersTable.length > 0 ? (
      dispatch(addUserListLoadingAction(newUsersTable))

    ) : page === "Markets" && newMarketGroupsTable.length > 0? (
      dispatch(addMarketGroupsLoadingAction(newMarketGroupsTable, propOr(0, "group_Id", selectedGroup)))
      

    ) : page === "SpreadOverrides" && newSpreadOverridesTable.length > 0 ? (
      dispatch(addSpreadOverridesLoadingAction(newSpreadOverridesTable,  propOr(0, "group_Id", selectedGroup)))
    ) : (
      console.log()
    )

    !objectsEqual(groupArray, pathOr(groupArray, ["data"], groupList)) && dispatch(addGroupsLoadingAction(groupArray));
    !objectsEqual(groupArray, pathOr(groupArray, ["data"], groupList)) && setPage("Markets");
  };

  return (
    <>
      <div>
        <div className={mainStyles.title}>Spread Configuration
          <span className={styles.saveButtonContainer}>
            <button disabled={!saveFlag} className={styles.saveButton} onClick={() => {saveGroupInfo(); setSaveFlag(false)}}>
              Save
            </button>
          </span>
        </div>
        {foldTag(groupList, {
          error: () => null,
          loading: () => <div>loading</div>,
          initial: () => null,
          loaded: (loadedData) => {
            return(
              <>
                <div className={styles.groupSelectHeader}>
                  <span>
                    Group
                    <select
                      className={styles.groupDropdown}
                      id="groupDropdown"
                      onChange={() => setGroup()}
                    >
                      {loadedData.data.map((group: any, index:number) => (
                        <option key={index} value={group.group_Name}>{group.group_Name}</option>
                      ))}
                    </select>
                  </span>

                  <span>
                    Lot Size:
                    
                    <input
                      type="number"
                      className={styles.groupDropdown}
                      id="lotDropdown"
                      placeholder={propOr(
                        loadedData.data[0].lot_Size,
                         "lot_Size", selectedGroup)}
                      onChange={(e) => {setLotSize(Number(e.target.value))}}
                    ></input>

                    MW
                  </span>

                  <span>
                    Transaction Type
                    <select
                      className={styles.groupDropdown}
                      id="transactionDropdown"
                      value={propOr("", "transcaction_Type", selectedGroup)}
                      onChange={(e: any) => setTransaction(e.target.value)}
                    >
                      <option value={"Buy"}>Buy</option>
                      <option value={"Sell"}>Sell</option>
                    </select>
                  </span>
                </div>
              </>
            )        
          }
        })}
        <NavigateModal 
          hasUnsavedChanges={saveFlag}
        ></NavigateModal>

        <div className="btn-group">
          <button
            type="button"
            className={page === "Markets" ? styles.btnHighlighted : styles.btn}
            onClick={() => (setPage("Markets"), setSaveFlag(false))}
          >
            <div>Markets</div>
          </button>

          <button
            type="button"
            className={
              page === "SpreadOverrides" ? styles.btnHighlighted : styles.btn
            }
            onClick={() => (setPage("SpreadOverrides"), setSaveFlag(false))}
          >
            <div>Spread Overrides</div>
          </button>

          <button
            type="button"
            className={page === "Users" ? styles.btnHighlighted : styles.btn}
            onClick={() => (setPage("Users"), setSaveFlag(false))}
          >
            <div>Users</div>
          </button>
        </div>

        <div className={styles.tableContainer}>
          {page === "Users" ? (
            <UsersTable
              selectedGroup={selectedGroup}
              setSaveFlag={setSaveFlag}
              setSaveTable={SetNewUsersTable}
            />
          ) : page === "Markets" ? (
            <MarketsTable
              selectedGroup={selectedGroup}
              setSaveFlag={setSaveFlag}
              setSaveTable={SetNewMarketGroupsTable}
            />
          ) : page === "SpreadOverrides" ? (
            <SpreadOverridesTable
              selectedGroup={selectedGroup}
              setSaveFlag={setSaveFlag}
              setSaveTable={setNewSpreadOverridesTable}
            />
          ) : (
            <></>
          )}
          <br />
        </div>
      </div>
    </>
  );
};

export default SpreadConfiguration;
