import { ActionsObservable, combineEpics } from "redux-observable";
import { defer } from "rxjs";
import { concatMap, switchMap } from "rxjs/operators";
import { ofType } from "../reduxObservableUtils";
import { Dependancies } from "../storeTypes";
import * as io from "io-ts";
import { pipe } from "fp-ts/lib/function";
import { match } from "fp-ts/lib/TaskEither";
import { dispatchNetworkError } from "./errorHandler";
import { SpreadOverride, spreadOverrideArrayDecoder } from "../decoders";

export const key = "closeTransaction";

const postCloseTransactionType = "user/POST_CLOSE_TRANSACTION/LOADING";
const  postCloseTransactionInitialType = "user/POST_CLOSE_TRANSACTION/INITIAL";
const postCloseTransactionSuccessType = "user/POST_CLOSE_TRANSACTION/LOADED";
const postCloseTransactionFailedType = "user/POST_CLOSE_TRANSACTION/FAILED";

export const postCloseTransactionSuccessAction = () =>
  ({ type: postCloseTransactionSuccessType } as const);

export const postCloseTransactionInitialAction = () =>
  ({ type: postCloseTransactionSuccessType } as const);

  
export const postCloseTransactionSetInitialAction = () =>
({ type: postCloseTransactionInitialType } as const);

export const postCloseTransactionFailedAction = {
  type: postCloseTransactionFailedType,
} as const;

export const postCloseTransactionAction = (transaction: any) =>
  ({
    type: postCloseTransactionType,
    transaction,
  } as const);

type Action =
  | typeof postCloseTransactionFailedAction
  | ReturnType<
      | typeof postCloseTransactionAction
      | typeof postCloseTransactionSuccessAction
      | typeof postCloseTransactionInitialAction
     >;

export type State =
  | { tag: "initial" }
  | { tag: "loading" }
  | { tag: "loaded" }
  | { tag: "error" };

const initialState: State = { tag: "initial" };

export const reducer = (state: State = initialState, action: any): State => {
  switch (action.type) {
    case postCloseTransactionInitialType:
        return {
        tag: "initial",
        };
    case postCloseTransactionType:
      return {
        tag: "loading",
      };
    case postCloseTransactionSuccessType:
      return {
        tag: "loaded",
      };

    case postCloseTransactionFailedType:
      return {
        tag: "error",
      };
    default:
      return state;
  }
};

const closeTransactionEpic = (
  action$: ActionsObservable<Action>,
  _: any,
  deps: Dependancies
) =>
  action$.pipe(
    ofType(postCloseTransactionType),
    concatMap((x) =>
      pipe(
        deps.request.post(
          `closeTransaction`,
          x.transaction,
          spreadOverrideArrayDecoder
        ),
        match(() => postCloseTransactionFailedAction as Action, (closeTransaction: any) => {
          return postCloseTransactionSuccessAction();
        }),
        defer
      )
    )
  );

export const epic = combineEpics(closeTransactionEpic);
const baseSelector: (a: any) => State = (s) => s[key];
export const Selectors = {
  all: baseSelector,
};
