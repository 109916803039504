import React, { useEffect, useState } from "react";
import mainStyles from "../styles/main.module.scss";
import styles from "./transactionLog.module.scss";
import {
  getTransactionsAction,
  Selectors as transactionSelector,
} from "../redux/modules/transactions";
import PaginationComponent from "./pagination";
import { useDispatch, useSelector } from "react-redux";
import { Transaction } from "../redux/decoders";
import { foldTag } from "../utils/fold";
import signalR, {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import {
  Selectors as ConfigSelectors,
  marketConfigLoadingAction,
} from "../redux/modules/marketConfig";
import {
  MarketDataList,
  OperationList,
  SignalrStatusType,
  User,
} from "../redux/decoders";
import { pathOr, replace } from "ramda";
import TrafficLight from "../components/trafficLight/view";
import { Selectors as userSelector } from "../redux/modules/user";
import { getAccessToken, signalRUrl } from "..";
import { roundToTwoDecimalPlaces, updateLiveState } from "../marketPrices/pages/helpers";

const TransactionLog = () => {
  const dispatch = useDispatch();
  const userDataTable: Transaction[] =
    useSelector(transactionSelector.transactions) || [];
  const transactionList = useSelector(transactionSelector.all) || [];

  const [state, setState] = useState<any | null>();

  let transactionLog: [] = pathOr([], ["TransactionLog"], state);
  const transformedData = convertKeysToCamelCase(transactionLog);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableType, setTableType] = useState("SignalR");
  const [tableCopied, setTableCopied] = useState(false);
  const [pause, setPause] = useState(false);
  const [inputText, setInputText] = useState("");
  const [connectionStatus, setConnectionStatus] = useState<any>({
    lastUpdate: "",
    state: "Connecting",
  });
  const [useLive, setUseLive] = useState(true);
  const tableData :Transaction[]=(tableType === "SignalR") ? transformedData: (tableType === "User") ?userDataTable:[];
  const [patchList, setPatch] = useState<any>([]);

  let user: User = useSelector(userSelector.data);

  useEffect(() => {
    dispatch(getTransactionsAction());
  }, []);

  const [hubGroup, setHubGroup] = useState<string>("");
  const groupName = pathOr(null, ["group"], user);

  let connection: HubConnection | null = null;

  
  if (patchList.length>0)
    updateLiveState(patchList, setPatch, state, setState);

  useEffect(() => {
    const groupId = pathOr(null, ["group_Id"], user);
    if (groupName) setHubGroup(replace(/\s/g, "", groupName) + "hub");
  }, [user]);
  useEffect(() => {
    if (hubGroup !== "") {
      connection = new HubConnectionBuilder()
        .withUrl(signalRUrl + hubGroup, {
          accessTokenFactory: async () => {
            const accessToken = await getAccessToken();
            if (accessToken) {
              return accessToken;
            } else {
              throw  Error("Access token is null or undefined");
            }
          },
        })
        .configureLogging(LogLevel.Information) // Optional: Enable logging
        .build();
        connection
        .start()
        .then(() => {
        })
        .catch((error) => {
          console.error(error);
          setConnectionStatus({ lastUpdate: "", state: "Error" });
        });
        connection.on("resetState", (newState, revision) => {
          if(!pause){
            setState(newState);
            setConnectionStatus({ lastUpdate: new Date(), state: "Connected" });
          }
        });
        connection.on("applyPatch", (patch, revision) => {
          if(!pause){
            // Check if state is defined before applying the patch
            setPatch(patch)
            setConnectionStatus({ lastUpdate: new Date(), state: "Connected" });
          }
        });
      
        return () => {
          // Clean up the connection when the component unmounts
          if (connection && connection.state === "Connected") {
            connection
              .stop()
              .then(() => {
                // Handle connection stop
                setConnectionStatus({ lastUpdate: connectionStatus.lastUpdate, state: "Disconnected" });
              })
              .catch((error) => {
                // Handle connection stop errors
                console.error(error);
                setConnectionStatus({ lastUpdate: "", state: "Error" });
              });
          }
        };
    }
  }, [hubGroup, pause]);

  const filteredData = tableData.filter((el: any) => {
    //if no input the return the original
    if (inputText === "") {
      return el;
    }
    //return the item which contains the user input
    else {
      return (
        el.marketName.toLowerCase().includes(inputText.toLowerCase()) ||
        el.absoluteProductName.toLowerCase().includes(inputText.toLowerCase())
      );
    }
  });

  //const currentPage = userDataTable.slice((page * pageSize)-pageSize, (page * pageSize));
  const currentPage = filteredData.slice(
    page * pageSize - pageSize,
    page * pageSize
  );
  const copyTable = () => {
    const elTable = document.querySelector("table")!.innerText;

    navigator.clipboard.writeText(elTable).then(
      () => {
        //console.log('copied')
      },
      () => {
        //console.log('failed')
      }
    );
  };
  const now = new Date(connectionStatus.lastUpdate);
  const formattedTime = now.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  const getColor = (state: string) => {
    if (state === "Connecting" || state === "Paused" || state === "Disconnected") return "yellow";
    if (state === "Connected") return "green";
    if (state === "Error") return "red";
    return "";
  };
  return (
    <div>
      <div className={mainStyles.title}>
        Transaction Log
        <span className={styles.controlButtonsContainer}>
          <button
            className={tableCopied ? styles.btnSelected : styles.btn}
            onClick={() => {
              setTableCopied(true);
              copyTable();
            }}
          >
            {tableCopied ? (
              <span className="fa fa-clipboard"> TX copied</span>
            ) : (
              <i className="fa fa-copy" aria-hidden="true"></i>
            )}
          </button>
          <button
            className={pause ? styles.btnSelected : styles.btn}
            onClick={() => {
              setPause(!pause);
              !pause ? setConnectionStatus({
                ...connectionStatus,
                state: "Connected",
              }):setConnectionStatus({
                ...connectionStatus,
                state: "Paused",
              });
            }}
          >
            {pause?<i className="fa fa-play" aria-hidden="true"></i>:<i className="fa fa-pause" aria-hidden="true"></i>}
          </button>
          <button
            className={tableType == "SignalR" ? styles.btnSelected : styles.btn}
            onClick={() => {
              setTableType("SignalR");
            }}
          >
            <i className="fa fa-lg fa-list-alt " aria-hidden="true"></i>
          </button>
          <button
            className={tableType == "User" ? styles.btnSelected : styles.btn}
            onClick={() => {
              setTableType("User");
            }}
          >
            <i className="fa fa-user" aria-hidden="true"></i>
          </button>
        </span>
        <div className={styles.lastUpdate}>
          {connectionStatus.lastUpdate &&
          !isNaN(Date.parse(connectionStatus.lastUpdate))
            ? "Last Update:" + formattedTime + " "
            : ""}
          <TrafficLight color={getColor(connectionStatus.state)}></TrafficLight>
        </div>
      </div>
      <div className={styles.searchBarContainer}>
        <input
          className={styles.searchBar}
          type="text"
          placeholder={"search"}
          onChange={(e) => setInputText(e.target.value)}
        ></input>
      </div>
      <div style={{ opacity: "0", position: "absolute", margin: "100px" }}>
        <table id="table">
          <thead>
            <tr key={"header"}>
              <th>Market</th>
              <th>Product</th>
              <th>Last Today(€/MWh)</th>
            </tr>
          </thead>
          <tbody>
            {tableType === "SignalR"
              ? tableData.map((data: any, index: number) => {
                  return (
                    <tr key={index + "-1"}>
                      <td>{data.marketName}</td>
                      <td>{data.absoluteProductName}</td>
                      <td align="right">{roundToTwoDecimalPlaces(data.price)}</td>
                    </tr>
                  );
                })
              : foldTag(transactionList, {
                  error: () => (
                    <tr className={styles.error}>
                      <td>error loading Table</td>
                    </tr>
                  ),
                  loading: () => (
                    <tr className={styles.loading}>
                      <td className="fas fa-spinner fa-2x fa-spin"></td>
                    </tr>
                  ),
                  initial: () => (
                    <tr className={styles.loading}>
                      <td className="icon fas fa-spinner fa-2x fa-spin"></td>
                    </tr>
                  ),
                  loaded: (loadedData) => {
                    return userDataTable.map((data: Transaction, index:number) => {
                      
                      return <tr key={index+ "-2"}>
                        <td>{data.marketName}</td>
                        <td>{data.absoluteProductName}</td>
                        <td align="right">{roundToTwoDecimalPlaces(data.price)}</td>
                      </tr>
                  });
                  },
                })}
          </tbody>
        </table>
      </div>
      <div>
        <table>
          <thead>
            <tr key={"header-2"}>
              <th>Market</th>
              <th>Product</th>
              <th>Last Today(€/MWh)</th>
            </tr>
          </thead>
          <tbody>
            {currentPage.length > 0 ? (
              tableType === "SignalR" ? (
                currentPage.map((data: any, index:number) => {
                  return (
                    <tr key={index + "-3"}>
                      <td>{data.marketName}</td>
                      <td>{data.absoluteProductName}</td>
                      <td align="right">{roundToTwoDecimalPlaces(data.price)}</td>
                    </tr>
                  );
                })
              ) : (
                foldTag(transactionList, {
                  error: () => (
                    <tr className={styles.error}>
                      <td>error loading Table</td>
                    </tr>
                  ),
                  loading: () => (
                    <tr className={styles.loading}>
                      <td className="fas fa-spinner fa-2x fa-spin"></td>
                    </tr>
                  ),
                  initial: () => (
                    <tr className={styles.loading}>
                      <td className="icon fas fa-spinner fa-2x fa-spin"></td>
                    </tr>
                  ),
                  loaded: (loadedData) => {

                    return currentPage.map((data: Transaction, index:number) => {
                      return <tr key={index+ "-4"}>
                        <td>{data.marketName}</td>
                        <td>{data.absoluteProductName}</td>
                        <td align="right">{roundToTwoDecimalPlaces(data.price)}</td>
                      </tr>
                  });
                  },
                })
              )
            ) : (
              <tr>
                <td colSpan={3} className="center">
                  No data available in table
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className={styles.tableFooter}>
        <span className={styles.pageSizeSelector}>
          Show
          <select
            defaultValue={pageSize}
            onChange={(e) => setPageSize(parseInt(e.target.value))}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select>
          entries
        </span>
        <PaginationComponent
          page={page}
          pageSize={pageSize}
          onPageChange={setPage}
          count={filteredData.length}
        />
      </div>
    </div>
  );
};

export default TransactionLog;

//************************workaroundfor signalr and endpoint misaligin in casing */
// Function to convert PascalCase to camelCase
const pascalToCamel = (str: string) =>
  str.charAt(0).toLowerCase() + str.slice(1);

// Function to recursively convert keys to camelCase
const convertKeysToCamelCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(convertKeysToCamelCase);
  } else if (obj !== null && typeof obj === "object") {
    const newObj: any = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = pascalToCamel(key);
        newObj[newKey] = convertKeysToCamelCase(obj[key]);
      }
    }
    return newObj;
  } else {
    return obj;
  }
};


