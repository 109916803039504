import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./spreadConfiguration.module.scss";
import mainStyles from "../styles/main.module.scss";
import {
  map,
  mergeDeepRight,
  pathOr,
} from "ramda";
import { number } from "io-ts";
import { foldTag } from "../utils/fold";
import { User } from "@auth0/auth0-spa-js";
import { getUserListLoadingAction, Selectors as usersSelector } from "../redux/modules/users";


export const UsersTable = (props:
    {
      selectedGroup: any,
      setSaveFlag: any,
      setSaveTable: any
    }) => {
        const dispatch = useDispatch();

        const usersList = useSelector(usersSelector.all);

        const [userList, setUserList] = useState<User[]>(pathOr([], ["data"], usersList));
        

        useEffect(() => {
            setUserList(pathOr([], ["data"], usersList))
        }, [usersList]);

        

        const setUserGroup = (newId: any, newGroupName: any, targetEmail: string) => {
            const newState = userList.map(obj => {
                if (obj.email === targetEmail) 
                    return {...obj, group_Id: newId, group: newGroupName};
                return obj;
            });
            setUserList(newState)
            props.setSaveTable(newState)
            props.setSaveFlag(true)
        };


        const setUserRights = (newValue: any, targetEmail: string) => {
            const newState = userList.map(obj => {
                if (obj.email === targetEmail) 
                    return {...obj, rights: newValue};
                return obj;
            });
            setUserList(newState)
            props.setSaveTable(newState)
            props.setSaveFlag(true)
        };
        

    return(
        <>
        <table>
            <thead>
            <tr key={"header"}>
                <th>Add/Remove</th>
                <th>Group</th>
                <th>Name</th>
                <th>Company</th>
                <th>Permission</th>
            </tr>
            </thead>
            <tbody>
            {foldTag(usersList, {
                error: () => null,
                loading: () => (
                    <tr className={styles.tableLoadingContainer}>
                    <td colSpan={9} style={{ textAlign: "center" }}>
                    <div
                      style={{ width: "100%", height: "100%" }}
                      className="fa fa-spinner fa-spin"
                    ></div>
                  </td>
                  </tr>),
                initial: () => null,
                loaded: (loaded) => {
                    
                    return (
                    <>
                    {loaded.data.map((user: any, index: number) => (
                        <tr key={user.email}>
                        <td>
                            {user.group === pathOr(0, ["selectedGroup","group_Name"], props) ?
                            <input
                            type="checkbox"
                            defaultChecked={
                                user.group === pathOr(0, ["selectedGroup","group_Name"], props) ? true : false
                            }
                            onChange={(e) =>
                                setUserGroup(
                                    e.target.checked === false ? null : pathOr(0, ["selectedGroup","group_Id"], props),
                                    e.target.checked === false ? null : pathOr(0, ["selectedGroup","group_Name"], props),
                                    user.email
                                )
                            }
                            />
                            : user.group === null ?
                            <input
                            type="checkbox"
                            defaultChecked={
                                false
                            }
                            onChange={(e) =>
                                setUserGroup(
                                    e.target.checked === true ? pathOr(0, ["selectedGroup","group_Id"], props) : null,
                                    e.target.checked === true ? pathOr(0, ["selectedGroup","group_Name"], props) : null,
                                    user.email
                                )
                            }
                            />
                            : <></>
                            }
                        </td>
                        <td>{user.group}</td>
                        <td>{user.name}</td>
                        <td>{user.company}</td>
                        <td>
                            <span>
                            <select
                                disabled={user.group != pathOr(0, ["selectedGroup","group_Name"], props)}
                                defaultValue={user.rights}
                                onChange={(e) => setUserRights(e.target.value, user.email)}
                            >
                                <option value={"RO"}>RO</option>
                                <option value={"RW"}>RW</option>
                            </select>
                            </span>
                        </td>
                        </tr>
                    ))}
                    </>
                    )
                    
                }
            })
            }
        </tbody>
        </table>
        </>

)};