import { useSelector } from "react-redux";
import { Selectors } from "./redux/modules/authentication";
import Main from "./main";
import AuthLoader from "./authLoader/view";
import "./App.css";
import Login from "./login/view";
import { Route, Switch } from "react-router";

function AuthenticatedMain() {
  const auth = useSelector(Selectors.auth);
  switch (auth.tag) {
    case "initial":
      return <AuthLoader />;
    case "authenticated":
      return <Main />;
    case "login-failed":
      return <AuthLoader />;
      // return <Login error={auth.error} />;
    case "login-required":
      return <AuthLoader />;
      // return <Login />;
  }
}
function App() {
  return (
    <Switch>
      <Route exact path="/Login" component={Login} />
      <Route component={AuthenticatedMain} />
    </Switch>
  );
}

export default App;
