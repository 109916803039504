import { ActionsObservable, combineEpics } from "redux-observable";
import { defer } from "rxjs";
import { concatMap, switchMap } from "rxjs/operators";
import { ofType } from "../reduxObservableUtils";
import { Dependancies } from "../storeTypes";
import * as io from "io-ts";
import { pipe } from "fp-ts/lib/function";
import { match } from "fp-ts/lib/TaskEither";
import { dispatchNetworkError } from "./errorHandler";
import { MarketGroup, marketGroupArrayDecoder } from "../decoders";


export const key = "marketGroup";


const getMarketGroupsLoadingType = "user/GET_MARKETGROUPS/LOADING";
const getMarketGroupsSuccessType = "user/GET_MARKETGROUPS/LOADED";
const getMarketGroupsFailedType = "user/GET_MARKETGROUPS/FAILED";

const addMarketGroupsLoadingType = "user/ADD_MARKETGROUPS/LOADING";
const addMarketGroupsSuccessType = "user/ADD_MARKETGROUPS/LOADED";
const addMarketGroupsFailedType = "user/ADD_MARKETGROUPS/FAILED";


export const getMarketGroupsLoadingAction = (groupId: number) =>
({
  type: getMarketGroupsLoadingType, groupId
} as const);

export const getMarketGroupsSuccessAction = (data: MarketGroup[]) =>
  ({ type: getMarketGroupsSuccessType, data } as const);

export const getMarketGroupsFailedAction = {
  type: getMarketGroupsFailedType,
} as const;

export const addMarketGroupsLoadingAction = (
    marketGroupList: MarketGroup[],
    groupId: number
    ) =>
  ({
    type: addMarketGroupsLoadingType, 
    marketGroupList,
    groupId
  } as const);
  
  export const addMarketGroupsSuccessAction = (marketGroups: MarketGroup[]) =>
    ({ type: addMarketGroupsSuccessType, marketGroups } as const);
  
  export const addMarketGroupsFailedAction = {
    type: addMarketGroupsFailedType,
  } as const;
  

type Action =
  | typeof getMarketGroupsFailedAction
  | typeof addMarketGroupsFailedAction

  | ReturnType<
    | typeof getMarketGroupsLoadingAction
    | typeof getMarketGroupsSuccessAction
    | typeof addMarketGroupsLoadingAction
    | typeof addMarketGroupsSuccessAction
  >;

  export type State =
  | { tag: "initial" }
  | { tag: "loading" }
  | {
      tag: "loaded";
      data: MarketGroup[];
    }
  | { tag: "error" };

const initialState: State = { tag: "initial" };

export const reducer = (state: State = initialState, action:any): State => {
    switch (action.type) {
      case getMarketGroupsLoadingType:
      return {
        ...state,
        tag: "loading"
      }
    case getMarketGroupsSuccessType: 
      return {
        ...state,
        tag: "loaded",
        data: action.data
      } 
    default:
      return state;
    }
  }

  const marketGroupsEpic = (
    action$: ActionsObservable<Action>,
    _: any,
    deps: Dependancies
  ) =>
    action$.pipe(
      ofType(getMarketGroupsLoadingType),
      concatMap((x) =>
        pipe(
          deps.request.get(
            `marketGroups/${x.groupId}`,
            marketGroupArrayDecoder
          ),
          match(dispatchNetworkError, (marketGroups: any) => {
            return getMarketGroupsSuccessAction(marketGroups);
          }),
          defer
        )
      )
    );

    const addMarketGroupsEpic = (
        action$: ActionsObservable<Action>,
        _: any,
        deps: Dependancies
      ) =>
        action$.pipe(
          ofType(addMarketGroupsLoadingType),
          switchMap((x) =>
            pipe(
              deps.request.post(
                `marketGroups/${x.groupId}`,
                x.marketGroupList,
                io.unknown
              ),
              match(dispatchNetworkError, () => getMarketGroupsLoadingAction(x.groupId)),
              defer
            )
          )
        );
    
export const epic = combineEpics(marketGroupsEpic, addMarketGroupsEpic);
const baseSelector: (a: any) => State = s => s[key]
export const Selectors = {
    all: baseSelector
    };