import * as t from "io-ts";
import "io-ts/lib/PathReporter";
import { PathReporter } from "io-ts/lib/PathReporter";
import { of, Observable, throwError } from "rxjs";
import { ajax } from "rxjs/ajax";
import { map, switchMap } from "rxjs/operators";
import { fold, left } from "fp-ts/lib/Either";

export const env = t.type({
  clientID: t.string,
  domain: t.string,
  callbackUrl: t.string,
  audience: t.string,
  serviceUrl: t.string,
});
export type envType = t.TypeOf<typeof env>;

const staticConfig: Observable<t.TypeOf<typeof env>> = of({
  clientID: "jatVUx7ONcZqLKFZaFG4tlUeiLRm5qzR",
  domain: "axpoit.eu.auth0.com",
  callbackUrl: "http://localhost:3000",
  audience: "https://test.api.clickone.axpo.it/",
  serviceUrl: "https://test-clickone-service-hjup5dwpuhcky.azurewebsites.net"
});
const connectionStringsConfig = ajax.get("/connectionStrings.js").pipe(
  map((x) => x.response),
  map(env.decode),
  switchMap(
    fold(
      (a) => throwError(() => PathReporter.report(left(a))),
      (x) => of(x)
    )
  )
);

export const getEnv: Observable<t.TypeOf<typeof env>> =
  process.env.NODE_ENV === "development"
    ? staticConfig
    : connectionStringsConfig;
