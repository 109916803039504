import React, { useEffect, useState } from "react";
import mainStyles from "../styles/main.module.scss";
import styles from "./spreadConfiguration.module.scss";
import {
  map,
  mergeDeepRight,
  mergeWithKey,
  pathOr,
} from "ramda";
import { number } from "io-ts";    
import { useDispatch, useSelector } from "react-redux";
import { getEmSpreadsLoadingAction, Selectors as emSpreadsSelector } from "../redux/modules/emSpreads";
import { addMarketGroupsLoadingAction, getMarketGroupsLoadingAction, Selectors as marketGroupsSelector } from "../redux/modules/marketGroups";
import { EmSpread, MarketGroup } from "../redux/decoders";
import { foldTag } from "../utils/fold";


            
export const MarketsTable = (props:
    {
      selectedGroup: any,
      setSaveFlag: any,
      setSaveTable: any
    }) => {     
        const dispatch = useDispatch();
          
        useEffect(() => {
          dispatch(getEmSpreadsLoadingAction(pathOr(0, ["selectedGroup","group_Id"], props)))
          dispatch(getMarketGroupsLoadingAction(pathOr(0, ["selectedGroup","group_Id"], props)))
          
        }, []);


        const emSpreadsList: EmSpread[] = useSelector(
          emSpreadsSelector.all
        );

        const marketGroupsList = useSelector(
          marketGroupsSelector.all
        );

        const [marketGroupList, setMarketGroupList] = useState<MarketGroup[]>(pathOr([], ["data"], marketGroupsList));

        const setBid = (newValue: any, targetMarketName: string) => {
          const newState = marketGroupList.map(obj => {
              if (obj.market_Name === targetMarketName) 
                  return {...obj, bid_Spread: newValue};
              return obj;
          });
          setMarketGroupList(newState);
          props.setSaveTable(newState);
          props.setSaveFlag(true)
        };

        const setAsk = (newValue: any, targetMarketName: string) => {
          const newState = marketGroupList.map(obj => {
              if (obj.market_Name === targetMarketName) 
                  return {...obj, ask_Spread: newValue};
              return obj;
          });
          setMarketGroupList(newState);
          props.setSaveTable(newState);
          props.setSaveFlag(true)
        };

        const setAvailable = (newValue: any, targetMarketName: string) => {
          const newState = marketGroupList.map(obj => {
              if (obj.market_Name === targetMarketName) 
                  return {...obj, available: newValue};
              return obj;
          });
          setMarketGroupList(newState);
          props.setSaveTable(newState);
          props.setSaveFlag(true)
        };

        useEffect(() => {
          setMarketGroupList(pathOr([], ["data"], marketGroupsList))
        }, [marketGroupsList]);
        
        const mergeById = (key: any, leftObj: any, rightObj: any) => {
            if (leftObj && rightObj) {
              
              return {
                group_Id: leftObj.group_Id,
                market: leftObj.market_Name,
                available: leftObj.available,
                emBid: rightObj.bid_Spread,
                emAsk: rightObj.ask_Spread,
                bid: leftObj.bid_Spread,
                ask: leftObj.ask_Spread,
                calcBid: 0,
                calcAsk: 0,
              };
            }
            return leftObj || rightObj;
          };

          const mergedArray = Object.values(mergeWithKey(mergeById, marketGroupList, emSpreadsList));

        return(
          <>
            
            <table>
              <thead>
                <tr key={"header"}>
                  <th></th>
                  <th>Market</th>
                  <th>EM Bid</th>
                  <th>EM Ask</th>
                  <th>Bid</th>
                  <th>Ask</th>
                  <th>Total Bid</th>
                  <th>Total Ask</th>
                </tr>
              </thead>
              <tbody>
              {foldTag(marketGroupsList, {
                error: () => null,
                loading: () => (
                  <tr className={styles.tableLoadingContainer}>
                  <td colSpan={9} style={{ textAlign: "center" }}>
                  <div
                    style={{ width: "100%", height: "100%" }}
                    className="fa fa-spinner fa-spin"
                  ></div>
                </td>
                </tr>),
                initial: () => null,
                loaded: (loaded) => {
                  const loadedMergedArray = Object.values(mergeWithKey(mergeById, loaded.data, emSpreadsList));
                  return(<>
                  {loadedMergedArray.map(
                    (marketsBidAskData: any, index: number) => {
                      return pathOr(0, ["group_Id"], marketsBidAskData) == pathOr(0, ["selectedGroup", "group_Id"], props) ?
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              defaultChecked={marketsBidAskData.available}
                              onChange={e => setAvailable(e.target.checked, marketsBidAskData.market)}
                            />
                          </td>

                          <td>{marketsBidAskData.market}</td>

                          <td>{marketsBidAskData.emBid}</td>
                          <td>{marketsBidAskData.emAsk}</td>
                          <td>
                            {marketGroupList.find(obj => obj.market_Name === marketsBidAskData.market)?.available ? <input
                              type="number"
                              defaultValue={marketsBidAskData.bid}
                              onChange={e => setBid(Number(e.target.value), marketsBidAskData.market)}
                            ></input> 
                            : <></>}
                          </td>
                          <td>
                            {marketGroupList.find(obj => obj.market_Name === marketsBidAskData.market)?.available ? <input
                              type="number"
                              defaultValue={marketsBidAskData.ask}
                              onChange={e => setAsk(Number(e.target.value), marketsBidAskData.market)}
                            ></input> 
                            : <></>}
                          </td>
                          <td>{(marketGroupList.find(obj => obj.market_Name === marketsBidAskData.market)?.bid_Spread + marketsBidAskData.emBid).toFixed(2)}</td>
                          <td>{(marketGroupList.find(obj => obj.market_Name === marketsBidAskData.market)?.ask_Spread + marketsBidAskData.emAsk).toFixed(2)}</td>
                        </tr>
                      : <></>
                    }
                  )}
                  </>)
                }
              })}
              </tbody>
            </table>
          </>
        )
    }
