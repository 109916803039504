import React, { useState, useEffect } from "react";
import styles from "./marketPrices.module.scss";
import { useDispatch, useSelector } from "react-redux";
import mainStyles from "../styles/main.module.scss";
import {
  toPairs,
  map,
  sort,
  pathOr,
  propOr,
  intersection,
  values,
  find,
  prop,
  replace,
  fromPairs,
  match,
  head,
  pipe
} from "ramda";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { Selectors as userSelector } from "../redux/modules/user";
import {
  Selectors as UserMarketsSelector,
  getUserMarketLoadingAction,
} from "../redux/modules/userMarket";
import {
  Selectors as ConfigSelectors
} from "../redux/modules/marketConfig";
import {
  CloseTransaction,
  SignalrStatusType,
  User,
} from "../redux/decoders";
import { postCloseTransactionAction } from "../redux/modules/closeTransaction";
import CloseTransactionModal from "./pages/closeTransactionModal";
import TrafficLight from "../components/trafficLight/view";
import { getDate, getProfile, getTransaction, roundToTwoDecimalPlaces, updateLiveState } from "./pages/helpers";
import { getAccessToken, signalRUrl } from "..";

const MarketPrices = () => {
  let user: User = useSelector(userSelector.data);
  const [state, setState] = useState<any | null>();
  const [signalRState, setSignalRState] = useState<any>({});
  const [connectionStatus, setConnectionStatus] = useState<SignalrStatusType>({
    lastUpdate: "",
    state: "Connecting",
  });
  const [liveDataStream, setLiveDataStream] = useState<any>([]);
  const [markets, setmarkets] = useState<any>([]);
  const [hubGroup, setHubGroup] = useState<string>("");
  const groupName = pathOr(null, ["group"], user);

  let connection: HubConnection | null = null;

  const [patchList, setPatch] = useState<any>([]);
  if (patchList.length>0)
    updateLiveState(patchList, setPatch, signalRState, setSignalRState);
  useEffect(() => {
    const groupId = pathOr(null, ["group_Id"], user);
    if (groupName) setHubGroup(replace(/\s/g, "", groupName) + "hub");

    if (groupId) {
      dispatch(getUserMarketLoadingAction(groupId));
    }
  }, [user]);
  useEffect(() => {
    if (hubGroup !== "") {
      connection = new HubConnectionBuilder()
        .withUrl(signalRUrl + hubGroup, {
          accessTokenFactory: async () => {
            const accessToken = await getAccessToken();
            if (accessToken) {
              return accessToken;
            } else {
              throw Error("Access token is null or undefined");
            }
          },
        })
        .configureLogging(LogLevel.Information) // Optional: Enable logging
        .build();
      connection
        .start()
        .then(() => {})
        .catch((error) => {
          console.error(error);
          setConnectionStatus({ lastUpdate: "", state: "Error" });
        });
      connection.on("resetState", (newState, revision) => {
        setSignalRState(newState);
        setConnectionStatus({ lastUpdate: getDate(), state: "Connected" });
      });
      connection.on("applyPatch", (patch, revision) => {
        // Check if state is defined before applying the patch
        setPatch(patch);
        setConnectionStatus({ lastUpdate: getDate(), state: "Connected" });
      });

      return () => {
        // Clean up the connection when the component unmounts
        if (connection && connection.state === "Connected") {
          connection
            .stop()
            .then(() => {
              // Handle connection stop
              setConnectionStatus({ lastUpdate: "", state: "Disconnected" });
            })
            .catch((error) => {
              // Handle connection stop errors
              setConnectionStatus({ lastUpdate: "", state: "Error" });
            });
        }
      };
    }
  }, [hubGroup]);
  const transactionInitialState: CloseTransaction = {
    direction: "",
    lotAmount: 1,
    lotSize: 0,
    marketName: "",
    price: 0,
    productName: "",
    profile: "",
    type: "",
  };

  const [sendTransaction, setSendTransaction] = useState<CloseTransaction>(
    transactionInitialState
  );
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const config: any = useSelector(ConfigSelectors.all);

  //***************table formatting***************/
  const marketColumns = ["Bid", "Ask", "Last"];

  const availableMarkts = JSON.parse(
    pathOr("{}", ["data", "livePricesGrid"], config)
  );
  const liveDataMarkets: string[] = pathOr([], ["Markets"], signalRState);
  const liveDataProducts = pathOr([], ["Products"], signalRState);

  const orderArray = ["M", "Q", "Cal", "S", "GY"];

  // Define a custom sorting function
  const customSort = (a: any, b: any) => {
    const aKey = a[0];
    const bKey = b[0];

    const aIndex = orderArray.findIndex((prefix) => aKey.startsWith(prefix));
    const bIndex = orderArray.findIndex((prefix) => bKey.startsWith(prefix));

    if (aIndex < bIndex) return -1;
    if (aIndex > bIndex) return 1;
    return aKey.localeCompare(bKey);
  };

  // Convert the input object into an array of key-value pairs
  const inputArrayAsArray: [string, any][] = toPairs(liveDataProducts);

  // Sort the array using the custom sorting function
  const sortedArray = sort(customSort, inputArrayAsArray);

  // Convert the sorted array back to an object
  const sortedObject = fromPairs(sortedArray);

  const tableData = values(sortedObject);
  var marketsWithOrder: string[] = intersection(
    liveDataMarkets,
    propOr([], "order", availableMarkts)
  );


  // Define state to track expanded kinds and displayed rows
  const [kindCounts, setKindCounts] = 
    useState<{ [kind: string]: number }>({"GasYear": 2, "Calendar": 2, "Month":4, "Quarter": 3, "Season":2});
  const [kindMax] = 
    useState<{ [kind: string]: number }>({"GasYear": 4, "Calendar": 4, "Month":4, "Quarter": 5, "Season":4});

  // Function to increment the count for a specific kind
  const incrementCount = (kind: string) => {
    setKindCounts((prevKindCounts) => ({
      ...prevKindCounts,
      [kind]: (prevKindCounts[kind] || 0) + 1,
    }));
  };

  // Function to decrement the count for a specific kind
  const decrementCount = (kind: string) => {
    setKindCounts((prevKindCounts) => {
      const currentCount = prevKindCounts[kind] || 0;
      if (currentCount > 0) {
        return {
          ...prevKindCounts,
          [kind]: currentCount - 1,
        };
      }
      return prevKindCounts;
    });
  };
  const dressedTable = map((row: any) =>
    map((column: any) => {
      const result = find(column, row.Markets);
      const matchingObject = prop(column, row.Markets);
      return {
        marketName: column,
        Market: matchingObject,
        rowData: row,
        Absolute: row.Absolute,
        Kind: row.Kind,
        Relative: row.Relative,
      };
    })(marketsWithOrder)
  )(tableData);

  //***************table formatting end***************/

  //***************Table Hover***************/
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [hoveredCol, setHoveredCol] = useState<number | null>(null);
  const handleCellHover = (row: number, col: number) => {
    setHoveredRow(row);
    setHoveredCol(col);
  };
  const handleMouseLeave = () => {
    setHoveredRow(null);
    setHoveredCol(null);
  };

  useEffect(() => {
    const groupId = pathOr(null, ["group_Id"], user);
    if (groupId) {
      dispatch(getUserMarketLoadingAction(groupId));
    }
  }, [user]);
  useEffect(() => {
    setLiveDataStream(pathOr([], ["Products"])(signalRState));
    setmarkets(pathOr([], ["Markets"])(signalRState));
  }, [signalRState]);

  function rowClick(transaction: CloseTransaction) {
    setSendTransaction(transaction);
    setModalOpen(true);
    //dispatch(postCloseTransactionAction(transaction))
  }
  //***************Table Hover End***************/

  //***************last updated and state***************/
  var colorCheck = "";

  switch (connectionStatus.state) {
    case "Connected":
      // code block
      colorCheck = "green";
      break;
    case "Connecting":
      colorCheck = "yellow";
      break;
    case "Error":
    case "Disconnected":
      colorCheck = "red";
      break;
    default:
  }

  const getColor = (state: string) => {
    if (state === "Connecting") return "yellow";
    if (state === "Connected") return "green";
    if (state === "Error" || state === "Disconnected") return "red";
    return "";
  };
  //***************last updated and state end***************/
  return (
    <>
      <div>
        <div className={styles.pageHeader}>
          <div className={mainStyles.title}>Market Prices</div>
          <div className={styles.lastUpdate}>
            {(connectionStatus.lastUpdate)
              ? "Last Update:" + connectionStatus.lastUpdate + " "
              : ""}
            <TrafficLight
              color={getColor(connectionStatus.state)}
            ></TrafficLight>
          </div>
        </div>

        <div className={styles.sortLists}>
          {connectionStatus.state === "Connecting" ? (
            <table className={styles.liveTable} style={{ overflow: "hidden" }}>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <div
                      style={{ width: "100%", height: "100%" }}
                      className="fa fa-spinner fa-spin"
                    ></div>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table className={styles.liveTable}>
              <thead>
                <tr className={styles.header}>
                  <th className="stickyColumn"></th>
                  {map((market: string) => (
                    <th key={market} colSpan={3}>
                      {market}
                    </th>
                  ))(marketsWithOrder)}
                </tr>

                <tr>
                  <th key={0} className="stickyColumn"></th>
                  {marketsWithOrder.map((market, index) => {
                    return marketColumns.map((header, headerIndex) => {
                      return (
                        <th
                          key={headerIndex + index * 3}
                          className={
                            hoveredRow === null
                              ? ""
                              : hoveredCol === headerIndex + (index * 3 + 1)
                              ? "highlighted"
                              : ""
                          }
                        >
                          {header}
                        </th>
                      );
                    });
                  })}
                </tr>
              </thead>
              <tbody>
                {dressedTable.map((row, rowIndex) => {
                  const parseNumber = pipe(
                    match(/\d+/),     // Extract numeric characters
                    head,             // Take the first match
                    Number              // Convert to a number
                  );
                  var abs:string = propOr("", "Relative", row[0])
                  var absNum:number= parseNumber(abs)
                  var kind:string = propOr("", "Kind", row[0])
                  var count = prop(kind, kindCounts)
                  return <React.Fragment key={abs+rowIndex + "-1"}>
                     {count >= absNum ? <tr onMouseLeave={handleMouseLeave}>
                     <td
                      className={
                        hoveredRow === rowIndex
                          ? "highlighted stickyColumn"
                          : "stickyColumn"
                      }
                    >
                     <>
                      <div className={styles.headingColumn}>


                      <span className={styles.titleTDName}>
                        {propOr("", "Absolute", row[0])}
                      </span>
                        {count === absNum ? <span className={styles.titleTDButtons}>
                          {absNum < prop(kind, kindMax) ? <span className="fa fa-plus blackIcon addRemove" onClick={() => incrementCount(propOr("", "Kind", row[0]))}></span>:<></>}
                          {absNum > 1 ? <span className="fa fa-minus blackIcon" onClick={() => decrementCount(propOr("", "Kind", row[0]))}></span>: <></>}
                          
            
                        </span> :<></>}



                      </div>
                      </>
                      
                    </td>
                      {row.map((cell: any, colIndex: number) => {
                        function isCheckUporDownIfNotNull(
                          lastTrade: number | null,
                          previousLastTrade: number | null
                        ) {
                          if (
                            lastTrade !== null &&
                            previousLastTrade !== null
                          ) {
                            if (lastTrade > previousLastTrade) {
                              return "up";
                            }
                            if (previousLastTrade > lastTrade) {
                              return "down";
                            }
                          }
                          // Return false if either value is null
                          return "";
                        }
                        var getIndex = (colOffset: number) =>
                          (colIndex + 1) * 3 + colOffset;
                        var lastTrade = pathOr(
                          null,
                          ["Market", "LastTrade"],
                          cell
                        );
                        var previousLastTrade = pathOr(
                          null,
                          ["Market", "PreviousLastTrade"],
                          cell
                        );
                        var relative = pathOr(
                          "",
                          ["Relative"],
                          cell
                        );
                        var upOrDown = isCheckUporDownIfNotNull(
                          lastTrade,
                          previousLastTrade
                        );
                        return (
                          <React.Fragment key={rowIndex+colIndex+relative}>
                            <td
                              align="right"
                              // currently disconnected
                              // onClick={() => {
                              //   var profile = getProfile(cell.marketName);
                              //   var transaction = getTransaction(
                              //     user,
                              //     cell,
                              //     profile,
                              //     "Bid"
                              //   );
                              //   return user.rights === "RW" &&
                              //     user.type === "Sell" &&
                              //     pathOr(null, ["Market", "BestBid"], cell) !==
                              //       null
                              //     ? rowClick(transaction)
                              //     : null;
                              // }}
                              key={rowIndex+colIndex+ getIndex(-2)}
                              onMouseEnter={() =>
                                handleCellHover(rowIndex, getIndex(-2))
                              }
                              className={`
              ${
                (hoveredRow === rowIndex && hoveredCol === getIndex(-2)) ||
                (hoveredRow === rowIndex && getIndex(-2) === 0)
                  ? "highlighted"
                  : ""
              }
              
            `}
                            >
                              {roundToTwoDecimalPlaces(pathOr(null, ["Market", "BestBid"], cell))}
                            </td>
                            <td
                              align="right"
                              
                              // currently disconnected
                              // onClick={() => {
                              //   var profile = getProfile(cell.marketName);
                              //   var transaction = getTransaction(
                              //     user,
                              //     cell,
                              //     profile,
                              //     "Ask"
                              //   );
                              //   return user.rights === "RW" &&
                              //     user.type === "Buy" &&
                              //     pathOr(null, ["Market", "BestAsk"], cell) !==
                              //       null
                              //     ? rowClick(transaction)
                              //     : null;
                              // }}
                              key={rowIndex+colIndex+ getIndex(-1)}
                              onMouseEnter={() =>
                                handleCellHover(rowIndex, getIndex(-1))
                              }
                              className={`
              ${
                (hoveredRow === rowIndex && hoveredCol === getIndex(-1)) ||
                (hoveredRow === rowIndex && getIndex(-1) === 0)
                  ? "highlighted"
                  : ""
              }
              
            `}
                            >
                              {roundToTwoDecimalPlaces(pathOr(null, ["Market", "BestAsk"], cell))}
                            </td>
                            <td
                              align="right"
                              onMouseEnter={() =>
                                handleCellHover(rowIndex, getIndex(0))
                              }
                              className={`
                          ${
                            upOrDown === "up"
                              ? "flash-green"
                              : upOrDown === "down"
                              ? "flash-red"
                              : ""
                          } ${
                                (hoveredRow === rowIndex &&
                                  hoveredCol === getIndex(0)) ||
                                (hoveredRow === rowIndex && getIndex(0) === 0)
                                  ? "highlighted"
                                  : ""
                              }
              
            `}
                            >
                              <>
                                {upOrDown === "up" ? (
                                  <i className="fa fa-caret-up deltaGreen"></i>
                                ) : upOrDown === "down" ? (
                                  <i className="fa fa-caret-down deltaRed"></i>
                                ) : (
                                  ""
                                )}
                                {roundToTwoDecimalPlaces(pathOr(null, ["Market", "LastTrade"], cell))}
                              </>
                            </td>
                          </React.Fragment>
                        );
                      })}
                    </tr> : <></>}
                  </React.Fragment>
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <CloseTransactionModal
        showModal={modalOpen}
        transaction={sendTransaction}
        sendTransaction={() =>
          dispatch(postCloseTransactionAction(sendTransaction))
        }
        close={() => setModalOpen(false)}
      />
    </>
  );
};


export default MarketPrices;



