import { ActionsObservable, combineEpics } from "redux-observable";
import { defer } from "rxjs";
import { concatMap, switchMap } from "rxjs/operators";
import { ofType } from "../reduxObservableUtils";
import { Dependancies } from "../storeTypes";
import * as io from "io-ts";
import { pipe } from "fp-ts/lib/function";
import { match } from "fp-ts/lib/TaskEither";
import { dispatchNetworkError } from "./errorHandler";
import { map, filter } from "ramda";
import { MarketGroup, marketGroupArrayDecoder } from "../decoders";


export const key = "userMarket";


const getUserMarketLoadingType = "userMarket/GET_USER_MARKET/LOADING";
const getUserMarketSuccessType = "userMarket/GET_USER_MARKET/LOADED";
const getUserMarketFailedType = "userMarket/GET_USER_MARKET/FAILED";


export const getUserMarketLoadingAction = (groupId: number) =>
({
  type: getUserMarketLoadingType, groupId
} as const);

export const getUserMarketSuccessAction = (data: MarketGroup[]) =>
  ({ type: getUserMarketSuccessType, data } as const);

export const getUserMarketGroupsFailedAction = {
  type: getUserMarketFailedType,
} as const;
  

type Action =
  | typeof getUserMarketGroupsFailedAction

  | ReturnType< 
    | typeof getUserMarketLoadingAction
    | typeof getUserMarketSuccessAction
  >;

  export type State =
  | { tag: "initial" }
  | { tag: "loading" }
  | {
      tag: "loaded";
      data: (string | null)[];
    }
  | { tag: "error" };

const initialState: State = { tag: "initial" };

export const reducer = (state: State = initialState, action:any): State => {
    switch (action.type) {
      case getUserMarketLoadingType:
      return {
        ...state,
        tag: "loading"
      }
    case getUserMarketSuccessType:{
      const availableMarkets = filter((row: MarketGroup) => row.available === true, action.data);
      const availableMarketsNames = map((row: MarketGroup) => row.market_Name,availableMarkets)
      return {
        ...state,
        tag: "loaded",
        data: availableMarketsNames
      }
    }
    default:
      return state;
    }
  }

  const marketGroupsEpic = (
    action$: ActionsObservable<Action>,
    _: any,
    deps: Dependancies
  ) =>
    action$.pipe(
      ofType(getUserMarketLoadingType),
      concatMap((x) =>
        pipe(
          deps.request.get(
            `marketGroups/${x.groupId}`,
            marketGroupArrayDecoder
          ),
          match(dispatchNetworkError, (marketGroups: any) => {
            return getUserMarketSuccessAction(marketGroups);
          }),
          defer
        )
      )
    );

export const epic = combineEpics(marketGroupsEpic);
const baseSelector: (a: any) => State = s => s[key]
export const Selectors = {
    all: baseSelector
    };