import { number } from "fp-ts";
import { CloseTransaction, OperationList, User } from "../../redux/decoders";

export function getProfile(market: string) {
    var profile = "";
    if (market.indexOf("Off-Peaks") !== -1) {
      profile = "OffPeak";
    } else if (market.indexOf("Peaks") !== -1) {
      profile = "Peak";
    } else if (market.indexOf("Baseload") !== -1) {
      profile = "BaseLoad";
    }
    return profile;
  }
  export function getTransaction(
    user: User,
    cell: any,
    profile: string,
    direction: string
  ) {
    var transaction: CloseTransaction = {
      lotAmount: 1,
      lotSize: user.lot_Size  || 0,
      type: direction === "Bid" ? "Sell" : direction === "Ask" ? "Buy" : "",
      price: direction === "Bid" ? cell.Market.BestBid : direction === "Ask" ? cell.Market.BestAsk : null,
      productName: cell.Absolute,
      marketName: cell.marketName,
      direction: direction,
      profile: profile,
    };
    return transaction;
  }
  
  export const updateLiveState = (
    patch: OperationList,
    setPatch: any,
    state: any,
    updateState: any,
  ) => {
    const updatedLiveData = { ...state };
  
    patch.forEach((update) => {
      const pathParts = update.path.split("/");
      if (pathParts[1] === "TransactionLog") {
        // Update the TransactionLog section
        const index = parseInt(pathParts[2], 10);
        updatedLiveData.TransactionLog[index] = {
          ...updatedLiveData.TransactionLog[index],
          [pathParts[3]]: update.value,
        };
      } else if (pathParts[1] === "Products") {
        // Update the Products section
        const productKey = pathParts[2];
        const market = pathParts[4];
        const property = pathParts[5];
  
        if (updatedLiveData.Products[productKey].Markets[market]) {
          // Update the specified property and keep other properties as is
          updatedLiveData.Products[productKey].Markets[market] = {
            ...updatedLiveData.Products[productKey].Markets[market],
            [property]: update.value,
          };
        }
      }
    });
  
    setPatch([])
    updateState(updatedLiveData);
  };
  
  export const getDate = () => {
    const now = new Date();
    const displayDate=now.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return displayDate;
  }

  export function roundToTwoDecimalPlaces(value: number | null) {
    if (value !== null) {
      return Number(value).toFixed(2);
    }
    return null;
  }
  