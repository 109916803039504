import { combineEpics } from "redux-observable";
import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import {
  key as authKey,
  reducer as authReducer,
  epic as authEpic,
} from "./modules/authentication";
import {
  key as userKey,
  reducer as userReducer,
  epic as userEpic,
} from "./modules/user";
import {
  key as marketConfigKey,
  reducer as marketConfigReducer,
  epic as marketConfigEpic,
} from "./modules/marketConfig";
import {
  key as errorHandlerKey,
  reducer as errorHandlersReducer,
} from "./modules/errorHandler";
import { key as menuKey, reducer as menuReducer } from "./modules/menu";

import {
  key as usersKey,
  reducer as usersReducer,
  epic as usersEpic
} from "./modules/users";
import {
  key as groupKey,
  reducer as groupReducer,
  epic as groupEpic
} from "./modules/group";

import {
  key as emSpreadsKey,
  reducer as emSpreadsReducer,
  epic as emSpreadsEpic
} from "./modules/emSpreads"
import {
  key as marketGroupsKey,
  reducer as marketGroupsReducer,
  epic as marketGroupsEpic
} from "./modules/marketGroups"
import {
  key as spreadOverridesKey,
  reducer as spreadOverridesReducer,
  epic as spreadOverridesEpic
} from "./modules/spreadOverrides"
import {
  key as productsKey,
  reducer as productsReducer,
  epic as productsEpic
} from "./modules/products"
import {
  key as userMarketKey,
  reducer as userMarketReducer,
  epic as userMarketEpic
} from "./modules/userMarket"

import {
  key as transactionsKey,
  reducer as transactionsReducer,
  epic as transactionsEpic
} from "./modules/transactions"

import {
  key as closeTransactionKey,
  reducer as closeTransactionReducer,
  epic as closeTransactionEpic
} from "./modules/closeTransaction"

export const rootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    [menuKey]: menuReducer,
    [userKey]: userReducer,
    [groupKey]: groupReducer,
    [emSpreadsKey]: emSpreadsReducer,
    [marketGroupsKey]: marketGroupsReducer,
    [productsKey]: productsReducer,
    [spreadOverridesKey]: spreadOverridesReducer,
    [authKey]: authReducer,
    [usersKey]: usersReducer,
    [marketConfigKey]: marketConfigReducer,
    [userMarketKey]: userMarketReducer,
    [closeTransactionKey]: closeTransactionReducer,
    [transactionsKey]: transactionsReducer,
    [errorHandlerKey]: errorHandlersReducer,
  });

export const rootEpic = combineEpics(
  authEpic,
  userEpic,
  usersEpic,
  groupEpic,
  spreadOverridesEpic,
  emSpreadsEpic,
  marketGroupsEpic,
  productsEpic,
  marketConfigEpic,
  userMarketEpic,
  closeTransactionEpic,
  transactionsEpic
);
